import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../firebase.js"; // Updated
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";

const SubscriptionCancel = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [dogName, setDogName] = useState("your pup"); // Default
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    const logCancellation = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDocRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setDogName(userDoc.data().dogName || "your pup");
          }
          const cancelDocRef = doc(firestore, "users", user.uid, "cancellations", Date.now().toString());
          await setDoc(cancelDocRef, {
            timestamp: serverTimestamp(),
            action: "canceled_subscription_attempt"
          });
          console.log("Cancellation logged for user:", user.uid);
        } catch (error) {
          console.error("Error logging cancellation:", error);
        }
      }
    };

    logCancellation();
  }, []);

  const handleTryAgain = () => {
    navigate("/");
  };

  const getStyles = () => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#F7FAFC",
      padding: isMobile ? "15px" : "20px",
      width: "100%",
      boxSizing: "border-box"
    },
    contentWrapper: {
      backgroundColor: "#fff",
      padding: isMobile ? "20px" : "40px",
      borderRadius: "12px",
      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
      width: "100%",
      maxWidth: isMobile ? "100%" : "600px",
      textAlign: "center"
    },
    heading: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: isMobile ? "32px" : "36px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "20px",
      textAlign: "center"
    },
    message: {
      fontSize: isMobile ? "16px" : "18px",
      color: "#666",
      textAlign: "center",
      marginBottom: "30px",
      lineHeight: isMobile ? "24px" : "28px"
    },
    tryAgainButton: {
      backgroundColor: "#007BFF",
      padding: isMobile ? "12px" : "15px",
      borderRadius: "50px",
      color: "#fff",
      fontSize: isMobile ? "16px" : "18px",
      fontWeight: "bold",
      cursor: "pointer",
      border: "none",
      width: isMobile ? "100%" : "200px",
      transition: "background-color 0.2s"
    },
    tryAgainButtonHover: {
      backgroundColor: "#0056b3"
    }
  });

  const styles = getStyles();

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <h1 style={styles.heading}>Subscription Canceled</h1>
        <p style={styles.message}>
          No worries! You’ve paused your subscription attempt. You’re missing out on tailored training for {dogName}—retry anytime to unlock 50+ skills with our special offer!
        </p>
        <button
          style={{ ...styles.tryAgainButton, ...(isHovered && styles.tryAgainButtonHover) }}
          onClick={handleTryAgain}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default SubscriptionCancel;