import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase.js";
import Logo from "../assets/DogTrainerAI.png";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);

  // Trigger logo animation on mount
  React.useEffect(() => {
    setIsLogoAnimated(true);
  }, []);

  const handleResetPassword = async () => {
    const trimmedEmail = email.trim();
    if (!trimmedEmail) {
      setError("Please enter your email.");
      return;
    }

    setIsLoading(true);
    setError("");
    setSuccess("");

    try {
      await sendPasswordResetEmail(auth, trimmedEmail);
      setSuccess("Password reset email sent! Check your inbox.");
      setTimeout(() => navigate("/login"), 3000); // Redirect to login after 3 seconds
    } catch (err) {
      console.error("Reset password error:", err.code, err.message);
      if (err.code === "auth/invalid-email") {
        setError("Invalid email format.");
      } else if (err.code === "auth/user-not-found") {
        setError("No user found with this email.");
      } else {
        setError("An error occurred: " + err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleResetPassword();
    }
  };

  const getButtonStyle = (baseStyle, hoverStyle, isHovered) => ({
    ...baseStyle,
    ...(isHovered ? hoverStyle : {})
  });

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#fff",
      padding: "20px",
    },
    logoContainer: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    logoButton: {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
      cursor: "pointer",
    },
    logo: {
      width: "100px",
      height: "100px",
      objectFit: "contain",
      animation: isLogoAnimated ? "fadeBounceIn 1s ease-out" : "none",
    },
    header: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: "32px",
      fontWeight: "bold",
      color: "#444",
      marginBottom: "20px",
      textAlign: "center",
    },
    formContainer: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "20px",
      width: "100%",
      maxWidth: "500px",
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxSizing: "border-box",
    },
    input: {
      width: "100%",
      maxWidth: "100%",
      padding: "15px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#ddd",
      borderRadius: "8px",
      fontSize: "18px",
      marginBottom: "20px",
      textAlign: "left",
      boxSizing: "border-box",
    },
    resetButton: {
      backgroundColor: "#007BFF",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
      borderRadius: "50px",
      width: "300px",
      alignItems: "center",
      marginBottom: "20px",
      border: "none",
      color: "#fff",
      fontSize: "18px",
      fontWeight: "600",
      cursor: "pointer",
      opacity: isLoading ? 0.7 : 1,
      pointerEvents: isLoading ? "none" : "auto",
    },
    resetButtonHover: {
      backgroundColor: "#0056b3",
    },
    linkButton: {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
      cursor: "pointer",
    },
    backToLogin: {
      color: "#007BFF",
      fontSize: "16px",
      textDecoration: "underline",
      marginTop: "20px",
    },
    error: {
      color: "red",
      marginBottom: "20px",
      textAlign: "center",
      fontSize: "16px",
    },
    success: {
      color: "#38A169",
      marginBottom: "20px",
      textAlign: "center",
      fontSize: "16px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <button style={styles.logoButton} onClick={() => navigate('/')}>
          <img src={Logo} alt="Doglingo Logo" style={styles.logo} />
        </button>
      </div>
      <h1 style={styles.header}>Reset Your Password</h1>
      <div style={styles.formContainer}>
        <input
          style={styles.input}
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
          autoCapitalize="none"
          type="email"
          disabled={isLoading}
        />
        {error && <p style={styles.error}>{error}</p>}
        {success && <p style={styles.success}>{success}</p>}
        <button
          style={getButtonStyle(styles.resetButton, styles.resetButtonHover, isHovered)}
          onClick={handleResetPassword}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={isLoading}
        >
          {isLoading ? "Sending..." : "Send Reset Email"}
        </button>
        <button style={styles.linkButton} onClick={() => navigate('/login')}>
          <p style={styles.backToLogin}>Back to Login</p>
        </button>
      </div>

      {/* Inline CSS for keyframes */}
      <style>
        {`
          @keyframes fadeBounceIn {
            0% {
              opacity: 0;
              transform: scale(0.8) translateY(-20px);
            }
            60% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1) translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

export default ForgotPasswordScreen;