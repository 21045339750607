import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { doc, setDoc, collection, getDocs } from "firebase/firestore"; // Removed unused getDoc
import { auth, firestore } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";

const RateScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category");
  const skillId = searchParams.get("skill_id");
  const skillName = searchParams.get("skill_name");

  const [user, setUser] = useState(null);
  const [rating, setRating] = useState(null);
  const [dogs, setDogs] = useState([]);
  const [selectedDogId, setSelectedDogId] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [hoverStates, setHoverStates] = useState({
    rating_1: false,
    rating_2: false,
    rating_3: false,
    rating_4: false,
    rating_5: false,
    save: false,
  });
  const isMobile = window.innerWidth < 768;

  // Ratings dictionary
  const ratings = {
    1: { icon: "⭐", label: "Not Learned" },
    2: { icon: "⭐⭐", label: "Some Progress" },
    3: { icon: "⭐⭐⭐", label: "Getting There" },
    4: { icon: "⭐⭐⭐⭐", label: "Almost Perfect" },
    5: { icon: "⭐⭐⭐⭐⭐", label: "Learned!" },
  };

  // Fetch user and their dogs
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        navigate("/login");
      } else {
        setUser(currentUser);
        if (!categoryId || !skillId || !skillName) {
          setError("Invalid skill selection. Please go back and choose a skill.");
          setLoading(false);
          return;
        }

        // Fetch user's dogs
        try {
          const dogsRef = collection(firestore, "users", currentUser.uid, "dogs");
          const dogsSnapshot = await getDocs(dogsRef);
          const dogsList = dogsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDogs(dogsList);
          if (dogsList.length > 0) {
            setSelectedDogId(dogsList[0].id); // Default to first dog
          }
        } catch (err) {
          setError(`Failed to load dogs: ${err.message}`);
        } finally {
          setLoading(false);
        }
      }
    });
    return () => unsubscribe();
  }, [categoryId, skillId, skillName, navigate]);

  // Save rating to Firestore under the selected dog's skillRatings
  const saveRating = async () => {
    if (!rating) {
      setError("Please select a rating first!");
      return;
    }
    if (!selectedDogId) {
      setError("Please select a dog first!");
      return;
    }
    if (!user) {
      setError("User not authenticated!");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const ratingData = {
        categoryId,
        skillId,
        skillName,
        rating,
        badgeEarned: rating === 5, // Award badge if rating is 5
        timestamp: new Date().toISOString(),
      };

      // Save to user's dog's skillRatings subcollection
      const ratingRef = doc(firestore, "users", user.uid, "dogs", selectedDogId, "skillRatings", skillId);
      await setDoc(ratingRef, ratingData, { merge: true });

      setSuccess(`Progress Saved! You rated ${skillName} as '${ratings[rating].label}' for your dog`);
      setTimeout(() => navigate("/skills"), 1500); // Navigate back after 1.5s
    } catch (err) {
      setError(`Failed to save rating: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#fff",
      padding: "20px",
      width: "100%",
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "600px",
      padding: "20px",
      textAlign: "center",
    },
    heading: {
      fontSize: "32px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "10px",
      textAlign: "center",
    },
    subheading: {
      fontSize: "18px",
      color: "#444",
      marginBottom: "20px",
      textAlign: "center",
    },
    selectContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "20px",
      width: "100%",
    },
    select: {
      width: "250px",
      padding: "10px",
      fontSize: "16px",
      borderRadius: "8px",
      border: "1px solid #ddd",
      textAlign: "center",
    },
    ratingContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      marginBottom: "20px",
      width: "100%",
    },
    ratingButton: {
      width: "250px",
      height: "100px",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: "12px",
      border: "3px solid #ddd",
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    ratingButtonSelected: {
      border: "3px solid rgb(162, 162, 235)", // Light blue border for selected
    },
    ratingButtonHover: {
      backgroundColor: "#f0f0f0",
      transform: "scale(1.05)",
    },
    saveButton: {
      width: "250px",
      height: "80px",
      fontSize: "24px",
      fontWeight: "bold",
      backgroundColor: "#4CAF50",
      color: "#fff",
      borderRadius: "12px",
      border: "none",
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
      marginTop: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    saveButtonHover: {
      backgroundColor: "#45a049",
      transform: "scale(1.05)",
    },
    message: {
      fontSize: "16px",
      marginTop: "10px",
      textAlign: "center",
    },
    success: {
      color: "#4CAF50",
    },
    error: {
      color: "red",
    },
    loadingText: {
      fontSize: "18px",
      color: "#666",
      textAlign: "center",
    },
  };

  if (loading) {
    return <p style={styles.loadingText}>Loading...</p>;
  }

  if (error && !rating) {
    return <p style={{ ...styles.message, ...styles.error }}>{error}</p>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <h1 style={styles.heading}>{skillName}</h1>
        <p style={styles.subheading}>How well did your dog learn this skill?</p>

        {dogs.length > 0 ? (
          <div style={styles.selectContainer}>
            <label htmlFor="dogSelect" style={{ marginBottom: "5px" }}>Select Dog:</label>
            <select
              id="dogSelect"
              value={selectedDogId}
              onChange={(e) => setSelectedDogId(e.target.value)}
              style={styles.select}
            >
              {dogs.map((dog) => (
                <option key={dog.id} value={dog.id}>
                  {dog.dogName || "Unnamed Dog"}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <p style={styles.error}>No dogs found. Please add a dog in your profile.</p>
        )}

        <div style={styles.ratingContainer}>
          {Object.entries(ratings).map(([value, { icon, label }]) => (
            <button
              key={value}
              style={{
                ...styles.ratingButton,
                ...(rating === parseInt(value) ? styles.ratingButtonSelected : {}),
                ...(hoverStates[`rating_${value}`] && rating !== parseInt(value) ? styles.ratingButtonHover : {}),
              }}
              onClick={() => setRating(parseInt(value))}
              onMouseEnter={() => setHoverStates((prev) => ({ ...prev, [`rating_${value}`]: true }))}
              onMouseLeave={() => setHoverStates((prev) => ({ ...prev, [`rating_${value}`]: false }))}
            >
              <span>{icon}</span>
              <span>{label}</span>
            </button>
          ))}
        </div>

        <button
          style={{
            ...styles.saveButton,
            ...(hoverStates.save && styles.saveButtonHover),
          }}
          onClick={saveRating}
          onMouseEnter={() => setHoverStates((prev) => ({ ...prev, save: true }))}
          onMouseLeave={() => setHoverStates((prev) => ({ ...prev, save: false }))}
          disabled={loading || !selectedDogId}
        >
          Save Progress
        </button>

        {success && <p style={{ ...styles.message, ...styles.success }}>{success}</p>}
        {error && <p style={{ ...styles.message, ...styles.error }}>{error}</p>}
      </div>
    </div>
  );
};

export default RateScreen;