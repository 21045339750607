import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/DogTrainerAI.png";

const PrivacyPolicyScreen = () => {
  const navigate = useNavigate();
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);

  useEffect(() => {
    setIsLogoAnimated(true);
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "#fff",
      padding: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      boxSizing: "border-box",
    },
    logoContainer: {
      marginTop: "20px",
      marginBottom: "20px",
      textAlign: "center",
    },
    logoButton: {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
      cursor: "pointer",
    },
    logo: {
      width: "100px",
      height: "100px",
      objectFit: "contain",
      animation: isLogoAnimated ? "fadeBounceIn 1s ease-out" : "none",
    },
    header: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: "32px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "20px",
      textAlign: "center",
    },
    date: {
      textAlign: "center",
    },
    section: {
      marginBottom: "20px",
    },
    sectionTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#444",
      marginBottom: "10px",
    },
    text: {
      fontSize: "16px",
      color: "#666",
      lineHeight: "1.5",
    },
    link: {
      color: "#007BFF",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <div style={styles.logoContainer}>
          <button style={styles.logoButton} onClick={() => navigate('/')}>
            <img src={Logo} alt="Doglingo Logo" style={styles.logo} />
          </button>
        </div>
        <h1 style={styles.header}>Privacy Policy</h1>
        <p style={styles.date}>Effective Date: March 18, 2025</p>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>1. Introduction</h2>
          <p style={styles.text}>
            Welcome to Doglingo! Your privacy is important to us. This Privacy Policy explains how we collect, use, and share your information when you use our app and services.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>2. Information We Collect</h2>
          <p style={styles.text}>We collect the following types of information:</p>
          <ul style={styles.text}>
            <li><strong>Personal Information:</strong> Name, email address, payment details (handled by Stripe).</li>
            <li><strong>Dog Data:</strong> Dog’s name, breed, behavior, training progress.</li>
            <li><strong>Technical Information:</strong> IP address, device type, cookies, and usage data.</li>
          </ul>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>3. How We Collect Information</h2>
          <p style={styles.text}>
            - When you sign up or update your profile.<br />
            - When you use our training tools and input dog-related data.<br />
            - Automatically through cookies and analytics tools.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>4. How We Use Your Information</h2>
          <p style={styles.text}>
            - To provide and improve Doglingo services.<br />
            - To send marketing emails (you can opt-out anytime).<br />
            - To process payments via Stripe.<br />
            - To analyze app usage and improve user experience.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>5. Data Sharing & Third Parties</h2>
          <p style={styles.text}>
            - We use Stripe for payment processing (see their privacy policy).<br />
            - We may use analytics services like Google Analytics.<br />
            - We do not sell your personal data to third parties.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>6. User Rights & Choices</h2>
          <p style={styles.text}>
            - You can request access, modification, or deletion of your data.<br />
            - You can opt out of marketing emails via unsubscribe links.<br />
            - GDPR and CCPA users have additional rights regarding data portability and deletion requests.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>7. Data Security</h2>
          <p style={styles.text}>
            - We use encryption and industry-standard security measures.<br />
            - However, we cannot guarantee complete security.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>8. Cookies & Tracking</h2>
          <p style={styles.text}>
            - We use cookies to improve user experience and track engagement.<br />
            - You can disable cookies in your browser settings.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>9. Changes to This Policy</h2>
          <p style={styles.text}>
            We may update this Privacy Policy from time to time. Changes will be communicated via email or in-app notifications.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>10. Contact Us</h2>
          <p style={styles.text}>
            For privacy-related inquiries, email us at <a href="mailto:hello@doglingo.com" style={styles.link}>hello@doglingo.com</a>.
          </p>
        </div>
      </div>

      <style>
        {`
          @keyframes fadeBounceIn {
            0% { opacity: 0; transform: scale(0.8) translateY(-20px); }
            60% { opacity: 1; transform: scale(1.1); }
            100% { opacity: 1; transform: scale(1) translateY(0); }
          }
        `}
      </style>
    </div>
  );
};

export default PrivacyPolicyScreen;