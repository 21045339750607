import axios from "axios";

const API_KEY = "xai-lJCSfaGMuTbooUlNGBphzwwmpgtqpAOozFX83SJmm73QhOuehSyz3Ee9FUR6k8u2dBFbE8lUhRfCKp5P"; // Your valid key
const grokApi = axios.create({
  baseURL: "https://api.x.ai/v1", // Official xAI API base URL
  headers: { Authorization: `Bearer ${API_KEY}` }
});

export const chat = (messages, options) =>
  grokApi.post("/chat/completions", { messages, ...options }) // Standard endpoint
    .then((response) => {
      console.log("Raw API response in grok.js:", JSON.stringify(response.data, null, 2));
      return response;
    })
    .catch((err) => {
      console.error("API call failed:", err.response ? JSON.stringify(err.response.data, null, 2) : err);
      throw err;
    });