import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/DogTrainerAI.png";

const TermsOfServiceScreen = () => {
  const navigate = useNavigate();
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);

  useEffect(() => {
    setIsLogoAnimated(true);
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "#fff",
      padding: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      boxSizing: "border-box",
    },
    logoContainer: {
      marginTop: "20px",
      marginBottom: "20px",
      textAlign: "center",
    },
    date: {
      textAlign: "center",
    },
    logoButton: {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
      cursor: "pointer",
    },
    logo: {
      width: "100px",
      height: "100px",
      objectFit: "contain",
      animation: isLogoAnimated ? "fadeBounceIn 1s ease-out" : "none",
    },
    header: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: "32px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "20px",
      textAlign: "center",
    },
    section: {
      marginBottom: "20px",
    },
    sectionTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#444",
      marginBottom: "10px",
    },
    text: {
      fontSize: "16px",
      color: "#666",
      lineHeight: "1.5",
    },
    link: {
      color: "#007BFF",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <div style={styles.logoContainer}>
          <button style={styles.logoButton} onClick={() => navigate('/')}>
            <img src={Logo} alt="Doglingo Logo" style={styles.logo} />
          </button>
        </div>
        <h1 style={styles.header}>Terms of Service</h1>
        <p style={styles.date}>Effective Date: March 18, 2025</p>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>1. Introduction</h2>
          <p style={styles.text}>
            Welcome to Doglingo! These Terms of Service govern your use of our app and services. By using Doglingo, you agree to these terms.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>2. User Accounts & Responsibilities</h2>
          <p style={styles.text}>
            - Users must provide accurate account information.<br />
            - You are responsible for maintaining account security.<br />
            - Any misuse of the service can result in account termination.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>3. Payments & Subscriptions</h2>
          <p style={styles.text}>
            - Payments are securely processed through Stripe.<br />
            - No credit card information is stored on our servers.<br />
            - Subscription plans auto-renew unless canceled before the billing cycle.<br />
            - No refunds unless required by law.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>4. Prohibited Activities</h2>
          <p style={styles.text}>
            Users may not:<br />
            - Use Doglingo for illegal activities.<br />
            - Attempt to hack or disrupt our service.<br />
            - Use or distribute Doglingo’s content without permission.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>5. Disclaimers & Liability</h2>
          <p style={styles.text}>
            - Doglingo provides training guidance, not guarantees.<br />
            - We are not responsible for any injury or damage related to training advice.<br />
            - Our service is provided &quot;as is&quot; without warranties.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>6. Intellectual Property</h2>
          <p style={styles.text}>
            - Doglingo and its content (logos, illustrations, training materials) belong to us.<br />
            - Users cannot copy, modify, or distribute our content without permission.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>7. Termination of Service</h2>
          <p style={styles.text}>
            - We reserve the right to suspend or terminate accounts that violate these terms.<br />
            - Subscriptions may be terminated if payment fails.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>8. Changes to Terms</h2>
          <p style={styles.text}>
            We may update these terms and notify users accordingly.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>9. Governing Law & Dispute Resolution</h2>
          <p style={styles.text}>
            - These terms are governed by the laws of Florida, USA.<br />
            - Any disputes must be resolved through arbitration or small claims court.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>10. Contact Us</h2>
          <p style={styles.text}>
            For questions regarding these terms, email us at <a href="mailto:hello@doglingo.com" style={styles.link}>hello@doglingo.com</a>.
          </p>
        </div>
      </div>

      <style>
        {`
          @keyframes fadeBounceIn {
            0% { opacity: 0; transform: scale(0.8) translateY(-20px); }
            60% { opacity: 1; transform: scale(1.1); }
            100% { opacity: 1; transform: scale(1) translateY(0); }
          }
        `}
      </style>
    </div>
  );
};

export default TermsOfServiceScreen;