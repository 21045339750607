import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore, storage } from "../firebase.js";
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from "firebase/auth";
import { doc, setDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import Logo from "../assets/DogTrainerAI.png";
import { Link } from "react-router-dom";

const questions = [
  { question: "What’s your dog’s name?", type: "text" },
  { question: "Upload a picture of your dog (optional)", type: "image" },
  { question: "Your dog is:", type: "radio", options: ["Girl", "Boy"] },
  { question: "Choose your dog's age:", type: "radio", options: ["Puppy (Less than a year old)", "Young (1 - 3 Years)", "Adult (3 - 7 Years)", "Senior (7+ Years)"] },
  { question: "What breed is your dog?", type: "text" },
  { 
    question: "What skills has your dog already learned?", 
    type: "multi", 
    options: [
      "Sit", "Stay", "Come", "Heel", "Shake", "Down", "Leave it",
      "Drop it", "Off", "Wait", "Watch me", "Fetch", "Roll over", "Speak", "Quiet", "Settle", "None"
    ]
  },
  { question: "Is your dog potty trained?", type: "radio", options: ["Yes", "No"] },
  { 
    question: "Any behavioral issues?", 
    type: "multi", 
    options: [
      "Aggression", "Separation Anxiety", "Excessive Barking", "Jumping", "Chewing",
      "Pulling on leash", "Counter-surfing", "Shyness", "None"
    ]
  },
  {
    question: "How active is your dog?",
    type: "radio",
    options: ["Very active (loves running)", "Moderately active", "Calm (prefers relaxing)"]
  },
  {
    question: "What’s your main training goal?",
    type: "radio",
    options: ["Obedience (e.g., sit, stay)", "Behavior (e.g., less barking)", "Fun tricks (e.g., roll over)", "Social skills (e.g., greeting politely)"]
  },
  { question: "Your Email:", type: "text" },
  { question: "Create a Password:", type: "password" }
];

const initialUserData = questions.reduce((acc, q) => {
  if (q.type === "radio" && q.options.length > 0) {
    acc[q.question] = q.options[0];
  } else if (q.type === "multi") {
    acc[q.question] = [];
  } else if (q.type === "image") {
    acc[q.question] = null;
  } else {
    acc[q.question] = "";
  }
  return acc;
}, {});

const OnboardingScreen = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [userData, setUserData] = useState(initialUserData);
  const [error, setError] = useState("");
  const [dogImage, setDogImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isMobile = window.innerWidth < 768;

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const maxSize = 5 * 1024 * 1024; // 5 MB limit
        if (file.size > maxSize) {
          setError("Image size exceeds 5 MB. Please upload a smaller image.");
          return;
        }
        // Revoke previous URL to prevent memory leaks
        if (dogImage) {
          URL.revokeObjectURL(dogImage);
        }
        const imageUrl = URL.createObjectURL(file);
        setDogImage(imageUrl);
        handleResponse(file);
      }
    },
  });

  useEffect(() => {
    setIsLogoAnimated(true);
    // Cleanup on unmount
    return () => {
      if (dogImage) {
        URL.revokeObjectURL(dogImage);
      }
    };
  }, [dogImage]);

  const handleResponse = (response) => {
    const question = questions[step].question;
    let updatedResponse = response;

    // Special handling for "None" in multi-select questions
    if (question === "Any behavioral issues?" || question === "What skills has your dog already learned?") {
      if (Array.isArray(response)) {
        if (response.includes("None") && response.length > 1) {
          updatedResponse = ["None"];
        } else if (response.includes("None") && response.length === 1) {
          updatedResponse = ["None"];
        } else if (response.length > 0 && userData[question]?.includes("None")) {
          updatedResponse = response.filter(item => item !== "None");
        }
      }
    }

    setUserData({ ...userData, [question]: updatedResponse });
  };

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPassword = (password) => password.length >= 6;
  const isValidDogName = (name) => name.trim().length > 0 && name.trim().length <= 50;

  const canProceed = () => {
    const question = questions[step].question;
    const response = userData[question];

    if (question === "What’s your dog’s name?") {
      return isValidDogName(response);
    }
    if (question === "Upload a picture of your dog (optional)") {
      return true; // Optional field
    }
    if (question === "What breed is your dog?") {
      return response.trim().length > 0;
    }
    if (question === "Your Email:") {
      return isValidEmail(response.trim());
    }
    if (question === "Create a Password:") {
      return isValidPassword(response);
    }
    if (questions[step].type === "multi") {
      return Array.isArray(response) && response.length > 0;
    }
    return response !== "";
  };

  const handleNext = async () => {
    const question = questions[step].question;
    const response = userData[question];

    if (!canProceed()) {
      if (question === "What’s your dog’s name?") {
        setError("Please enter a valid dog name (1-50 characters).");
      } else if (question === "What breed is your dog?") {
        setError("Please enter your dog’s breed.");
      } else if (question === "Your Email:") {
        setError("Please enter a valid email address.");
      } else if (question === "Create a Password:") {
        setError("Password must be at least 6 characters.");
      } else if (questions[step].type === "multi") {
        setError("Please select at least one option.");
      } else {
        setError("Please provide an answer.");
      }
      return;
    }

    if (question === "Your Email:") {
      const email = response.trim();
      try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        if (signInMethods.length > 0) {
          setError(
            <div>
              <span>Email already in use.</span>
              <br />
              <button
                onClick={() => navigate("/login")}
                style={styles.loginLinkButton}
              >
                <span style={styles.loginLink}>Would you like to login instead?</span>
              </button>
            </div>
          );
          return;
        }
      } catch (err) {
        console.error("Error checking email existence:", err);
        setError("Unable to verify email. Please try again later.");
        return;
      }
    }

    setError("");

    if (step === questions.length - 1) {
      const email = userData["Your Email:"].trim();
      const password = userData["Create a Password:"];

      try {
        console.log("Starting onboarding for email:", email);
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log("User created in Authentication:", user.uid);

        let imageUrl = null;
        if (dogImage && userData[questions[1].question]) {
          setUploading(true);
          try {
            console.log("Uploading image to Firebase Storage...");
            const file = userData[questions[1].question];
            const storageRef = ref(storage, `dogImages/${user.uid}/${file.name}`);
            await uploadBytes(storageRef, file);
            imageUrl = await getDownloadURL(storageRef);
            console.log("Image uploaded to Storage, download URL:", imageUrl);
          } catch (uploadErr) {
            console.error("Storage upload error:", uploadErr);
            setError("Failed to upload image. Please try again.");
            setUploading(false);
            return;
          }
          setUploading(false);
        } else {
          console.log("No image provided.");
        }

        const userDocRef = doc(firestore, "users", user.uid);
        const userDataToSave = {
          email,
          createdAt: new Date().toISOString(),
          freeSkillClaimed: false,
        };
        console.log("Attempting to save user data to Firestore:", userDataToSave);
        await setDoc(userDocRef, userDataToSave, { merge: true });
        console.log("User data successfully saved to Firestore at:", userDocRef.path);

        const dogDocRef = doc(collection(firestore, "users", user.uid, "dogs"));
        const dogData = {
          dogName: userData["What’s your dog’s name?"].trim(),
          dogGender: userData["Your dog is:"],
          dogAge: userData["Choose your dog's age:"],
          dogBreed: userData["What breed is your dog?"].trim(),
          skills: (userData["What skills has your dog already learned?"] || []).map(skill => skill.toLowerCase()), // Normalize to lowercase
          pottyTrained: userData["Is your dog potty trained?"],
          behavioralIssues: userData["Any behavioral issues?"] || [],
          activityLevel: userData["How active is your dog?"],
          trainingGoal: userData["What’s your main training goal?"],
          dogImage: imageUrl,
          createdAt: new Date().toISOString(),
        };
        console.log("Attempting to save dog data to Firestore:", dogData);
        await setDoc(dogDocRef, dogData);
        console.log("Dog data successfully saved to Firestore at:", dogDocRef.path);

        navigate("/dashboard");
      } catch (err) {
        console.error("Onboarding error:", {
          code: err.code || "unknown",
          message: err.message,
          stack: err.stack,
        });
        if (err.code === "auth/email-already-in-use") {
          setError(
            <div>
              <span>Email already in use.</span>
              <br />
              <button
                onClick={() => navigate("/login")}
                style={styles.loginLinkButton}
              >
                <span style={styles.loginLink}>Would you like to login instead?</span>
              </button>
            </div>
          );
        } else if (err.code === "auth/network-request-failed") {
          setError("Network error. Please check your connection and try again.");
        } else if (err.code === "auth/invalid-email") {
          setError("Invalid email format. Please check your email.");
        } else {
          setError("Error registering: " + err.message);
        }
        setUploading(false);
      }
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && canProceed()) {
      handleNext();
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderQuestion = () => {
    const { question, type, options } = questions[step];
    if (type === "text") {
      return (
        <input
          style={styles.input}
          placeholder="Your answer"
          type={type}
          value={userData[question] || ""}
          onChange={(e) => handleResponse(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      );
    } else if (type === "password") {
      return (
        <div style={styles.passwordContainer}>
          <input
            style={styles.passwordInput}
            placeholder="Enter your password"
            type={showPassword ? "text" : "password"}
            value={userData[question] || ""}
            onChange={(e) => handleResponse(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button style={styles.eyeIcon} onClick={toggleShowPassword}>
            <span style={styles.eyeIconText}>{showPassword ? "👁️" : "👁️‍🗨️"}</span>
          </button>
        </div>
      );
    } else if (type === "radio") {
      return (
        <div style={styles.radioWrapper}>
          {options.map((opt) => (
            <button
              key={opt}
              style={styles.radioContainer}
              onClick={() => handleResponse(opt)}
              onKeyPress={(e) => e.key === "Enter" && handleResponse(opt)}
              tabIndex={0}
            >
              <div style={{ ...styles.radioCircle, ...(userData[question] === opt && styles.radioSelected) }} />
              <span style={styles.radioLabel}>{opt}</span>
            </button>
          ))}
        </div>
      );
    } else if (type === "multi") {
      return (
        <div style={styles.radioWrapper}>
          {options.map((opt) => (
            <button
              key={opt}
              style={styles.radioContainer}
              onClick={() => {
                const current = userData[question] || [];
                const newValue = current.includes(opt)
                  ? current.filter((item) => item !== opt)
                  : [...current, opt];
                handleResponse(newValue);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const current = userData[question] || [];
                  const newValue = current.includes(opt)
                    ? current.filter((item) => item !== opt)
                    : [...current, opt];
                  handleResponse(newValue);
                }
              }}
              tabIndex={0}
            >
              <div style={{ ...styles.radioCircle, ...(userData[question]?.includes(opt) && styles.radioSelected) }} />
              <span style={styles.radioLabel}>{opt}</span>
            </button>
          ))}
        </div>
      );
    } else if (type === "image") {
      return (
        <div>
          <button
            {...getRootProps()}
            style={styles.imagePicker}
            onKeyPress={(e) => e.key === "Enter" && getRootProps().onClick(e)}
            tabIndex={0}
          >
            <input {...getInputProps()} />
            <span style={styles.imagePickerText}>📸 Upload a Picture (Drag or Click)</span>
          </button>
          {dogImage && <img src={dogImage} style={styles.dogImagePreview} alt="Dog Preview" />}
          {uploading && <p style={styles.uploadingText}>Uploading...</p>}
        </div>
      );
    }
  };

  const getStyles = () => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#fff",
      padding: "20px",
    },
    loginLinkButton: {
      background: "none",
      border: "none",
      padding: 0,
      cursor: "pointer",
    },
    loginLink: {
      fontSize: "14px",
      color: "#666",
      textDecoration: "none",
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "600px",
      padding: isMobile ? "10px" : "20px",
      boxSizing: "border-box",
    },
    progressBar: {
      width: "100%",
      height: "10px",
      backgroundColor: "#E2E8F0",
      marginBottom: "20px",
    },
    header: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: "32px",
      fontWeight: "bold",
      color: "#444",
      marginBottom: "20px",
      textAlign: isMobile ? "center" : "left",
    },
    input: {
      width: "100%",
      maxWidth: "100%",
      padding: "15px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#ddd",
      borderRadius: "8px",
      fontSize: "18px",
      boxSizing: "border-box",
    },
    passwordContainer: {
      width: "100%",
      position: "relative",
      marginBottom: "20px",
    },
    passwordInput: {
      width: "100%",
      maxWidth: "100%",
      padding: "15px",
      paddingRight: "50px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#ddd",
      borderRadius: "8px",
      fontSize: "18px",
      textAlign: "left",
      boxSizing: "border-box",
    },
    eyeIcon: {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      backgroundColor: "transparent",
      border: "none",
      padding: "5px",
      cursor: "pointer",
    },
    eyeIconText: {
      fontSize: "24px",
    },
    radioWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "flex-start",
    },
    radioContainer: {
      display: "flex",
      alignItems: "center",
      padding: "10px",
      cursor: "pointer",
      background: "none",
      border: "none",
    },
    radioCircle: {
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#ccc",
      marginRight: "10px",
    },
    radioSelected: {
      backgroundColor: "#007BFF",
    },
    radioLabel: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#444",
    },
    imagePicker: {
      backgroundColor: "#f0f0f0",
      padding: "40px",
      borderRadius: "8px",
      textAlign: "center",
      margin: "10px 0",
      cursor: "pointer",
      width: "100%",
      boxSizing: "border-box",
      minHeight: "100px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    imagePickerText: {
      color: "#000",
      fontSize: "18px",
    },
    dogImagePreview: {
      width: "200px",
      height: "200px",
      borderRadius: "50%",
      marginTop: "10px",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    uploadingText: {
      fontSize: "16px",
      textAlign: "center",
      marginTop: "10px",
    },
    error: {
      color: "red",
      marginBottom: "20px",
      textAlign: "center",
      fontSize: "16px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "20px",
    },
    backButton: {
      padding: "10px 20px",
      backgroundColor: "#fff",
      border: "2px solid #ccc",
      fontSize: "18px",
      fontWeight: "400",
      borderRadius: "50px",
      width: "150px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
    },
    backButtonHover: {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      transform: "translateY(-2px)",
    },
    backButtonActive: {
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
      transform: "translateY(1px)",
    },
    nextButton: {
      padding: "10px 20px",
      backgroundColor: "#38A169",
      border: "none",
      width: "150px",
      borderRadius: "50px",
      cursor: "pointer",
      opacity: canProceed() && !uploading ? 1 : 0.5,
      pointerEvents: canProceed() && !uploading ? "auto" : "none",
    },
    buttonText: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "400",
    },
    logo: {
      width: isMobile ? "100px" : "150px",
      height: "auto",
      display: "block",
      margin: "0 auto 20px",
      objectFit: "contain",
      animation: isLogoAnimated ? "fadeBounceIn 1s ease-out" : "none",
    },
    footerLinks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "150px",
      marginBottom: "20px",
      width: "100%",
    },
    agreementText: {
      fontSize: "14px",
      color: "#666",
      textAlign: "center",
      marginBottom: "20px",
    },
    footerLinkButton: {
      background: "none",
      border: "none",
      padding: 0,
      cursor: "pointer",
    },
    footerLink: {
      fontSize: "16px",
      color: "#666",
      textDecoration: "none",
    },
  });

  const styles = getStyles();
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <Link to="/" style={{ display: "block", textAlign: "center" }}>
          <img src={Logo} alt="Doglingo Logo" style={styles.logo} />
        </Link>

        <div style={styles.progressBar}>
          <div style={{ width: `${((step + 1) / questions.length) * 100}%`, height: "10px", backgroundColor: "#38A169" }} />
        </div>
        <h2 style={styles.header}>{questions[step].question}</h2>
        {renderQuestion()}
        {error && <span style={styles.error}>{error}</span>}
        <div style={styles.buttonContainer}>
          {step > 0 && (
            <button
              style={{
                ...styles.backButton,
                ...(hovered ? styles.backButtonHover : {}),
                ...(clicked ? styles.backButtonActive : {}),
              }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onMouseDown={() => setClicked(true)}
              onMouseUp={() => setClicked(false)}
              onClick={handleBack}
            >
              Back
            </button>
          )}
          <button style={styles.nextButton} onClick={handleNext}>
            <span style={styles.buttonText}>{step === questions.length - 1 ? "Start" : "Next"}</span>
          </button>
        </div>
      </div>

      <style>
        {`
          @keyframes fadeBounceIn {
            0% {
              opacity: 0;
              transform: scale(0.8) translateY(-20px);
            }
            60% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1) translateY(0);
            }
          }
        `}
      </style>

      {step === questions.length - 1 && (
        <div style={styles.footerLinks}>
          <p style={styles.agreementText}>
            By creating an account, you agree to our{" "}
            <button
              onClick={() => window.open("/privacy-policy", "_blank")}
              style={styles.footerLinkButton}
            >
              <span style={styles.footerLink}>Privacy Policy</span>
            </button>{" "}
            and{" "}
            <button
              onClick={() => window.open("/terms-of-service", "_blank")}
              style={styles.footerLinkButton}
            >
              <span style={styles.footerLink}>Terms of Service</span>
            </button>
            .
          </p>
        </div>
      )}
    </div>
  );
};

export default OnboardingScreen;