import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase.js';
import Logo from "../assets/DogTrainerAI.png";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false); // Added for animation

  useEffect(() => {
    // Trigger animation on mount
    setIsLogoAnimated(true);

    console.log('LoginScreen: Checking auth state...');
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('LoginScreen: onAuthStateChanged fired, user:', user ? user.uid : 'null');
      if (user) {
        console.log('LoginScreen: User logged in, navigating to SkillsScreen');
        navigate('/skills');
      }
      setIsLoading(false);
    });
    return () => {
      console.log('LoginScreen: Unsubscribing from auth state');
      unsubscribe();
    };
  }, [navigate]);

  const handleLogin = async () => {
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    console.log('LoginScreen: Attempting login with email:', trimmedEmail);

    if (!trimmedEmail || !trimmedPassword) {
      setError('Please enter both email and password.');
      console.log('LoginScreen: Missing email or password');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, trimmedEmail, trimmedPassword);
      const user = userCredential.user;
      const idToken = await user.getIdToken();

      console.log('LoginScreen: Login successful, user:', user.uid);

      await setDoc(doc(firestore, 'sessions', user.uid), {
        user_email: trimmedEmail,
        id_token: idToken,
        local_id: user.uid,
        is_authenticated: true,
        lastLogin: new Date().toISOString()
      }, { merge: true });

      console.log('LoginScreen: Session data stored in Firestore');
    } catch (err) {
      console.error('LoginScreen: Login error:', err.code, err.message);
      if (err.code === 'auth/invalid-email' || err.code === 'auth/wrong-password' || err.code === 'auth/user-not-found') {
        setError('Invalid credentials, please try again.');
      } else if (err.code === 'auth/too-many-requests') {
        setError('Too many failed login attempts. Please try again later.');
      } else {
        setError(`An error occurred: ${err.message}`);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getButtonStyle = (baseStyle, hoverStyle, isHovered) => ({
    ...baseStyle,
    ...(isHovered ? hoverStyle : {})
  });

  if (isLoading) {
    return <p style={styles.loading}>Loading...</p>;
  }

  console.log('LoginScreen: Rendering login form');

  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <button style={styles.logoButton} onClick={() => navigate('/')}>
          <img src={Logo} alt="Doglingo Logo" style={styles.logo} /> {/* Updated with animation */}
        </button>
      </div>
      <h1 style={styles.header}>Login to Doglingo</h1>
      <div style={styles.formContainer}>
        <input
          style={styles.input}
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
          autoCapitalize="none"
          type="email"
        />
        <div style={styles.passwordContainer}>
          <input
            style={styles.passwordInput}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            type={showPassword ? 'text' : 'password'}
          />
          <button
            style={styles.eyeIcon}
            onClick={toggleShowPassword}
          >
            <span style={styles.eyeIconText}>{showPassword ? '👁️' : '👁️‍🗨️'}</span>
          </button>
        </div>
        {error ? <p style={styles.error}>{error}</p> : null}
        <button
          style={getButtonStyle(styles.loginButton, styles.loginButtonHover, isHovered)}
          onClick={handleLogin}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Login
        </button>
        <button style={styles.linkButton} onClick={() => navigate('/forgot-password')}>
          <p style={styles.forgotPassword}>Forgot Password?</p>
        </button>
      </div>
      <button style={styles.linkButton} onClick={() => navigate('/onboarding')}>
        <p style={styles.createAccount}>Create an Account</p>
      </button>

      {/* Inline CSS for keyframes */}
      <style>
        {`
          @keyframes fadeBounceIn {
            0% {
              opacity: 0;
              transform: scale(0.8) translateY(-20px);
            }
            60% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1) translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#fff',
    padding: '20px'
  },
  logoContainer: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  logoButton: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    cursor: 'pointer'
  },
  logo: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    animation: 'fadeBounceIn 1s ease-out' // Applied animation directly
  },
  header: {
    fontFamily: 'MouseMemoirs, sans-serif',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#444',
    marginBottom: '20px',
    textAlign: 'center',
    paddingBottom: '20px'
  },
  formContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '20px',
    width: '100%',
    maxWidth: '500px',
    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  input: {
    width: '100%',
    maxWidth: '100%',
    padding: '15px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ddd',
    borderRadius: '8px',
    fontSize: '18px',
    marginBottom: '20px',
    textAlign: 'left',
    boxSizing: 'border-box'
  },
  passwordContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: '20px'
  },
  passwordInput: {
    width: '100%',
    maxWidth: '100%',
    padding: '15px',
    paddingRight: '50px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ddd',
    borderRadius: '8px',
    fontSize: '18px',
    textAlign: 'left',
    boxSizing: 'border-box'
  },
  eyeIcon: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '5px',
    cursor: 'pointer'
  },
  eyeIconText: {
    fontSize: '24px'
  },
  loginButton: {
    backgroundColor: '#007BFF',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '50px',
    width: '300px',
    alignItems: 'center',
    marginBottom: '20px',
    border: 'none',
    color: '#fff',
    fontSize: '18px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  loginButtonHover: {
    backgroundColor: '#0056b3'
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    cursor: 'pointer'
  },
  forgotPassword: {
    color: '#007BFF',
    fontSize: '16px',
    textDecoration: 'underline',
    margin: 0
  },
  createAccount: {
    color: '#007BFF',
    fontSize: '16px',
    textDecoration: 'underline',
    paddingTop: '30px'
  },
  error: {
    color: 'red',
    marginBottom: '20px',
    textAlign: 'center',
    fontSize: '16px'
  },
  loading: {
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '20px'
  }
};

export default LoginScreen;