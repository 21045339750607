import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LandingImage from "../assets/landing-page-graphic-AI-Dog-Trainer.webp";
import Logo from "../assets/DogTrainerAI.png";
import { auth } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";

const isMobile = window.innerWidth < 768;

const MainScreen = () => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const getButtonStyle = (baseStyle, hoverStyle, isHovered) => ({
    ...baseStyle,
    ...(isHovered ? hoverStyle : {}),
  });

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <div style={styles.heroContainer}>
          <div style={styles.heroImageContainer}>
            <div style={styles.imageWrapper}>
              <img
                src={LandingImage}
                alt="AI Dog Trainer"
                style={styles.heroImage}
                onError={(e) => console.log("Image load failed:", e.nativeEvent.error)}
              />
            </div>
          </div>
          <div style={styles.heroTextContainer}>
            <h1 style={styles.heroTitle}>
              Train Your Dog Like a Pro – Anytime, Anywhere
            </h1>
            <p style={styles.heroSubtitle}>
              AI-powered real-time training for better behavior & obedience.
            </p>
            <button
              style={getButtonStyle(styles.buttonBlue, styles.buttonBlueHover, isButtonHovered)}
              onClick={() => {
                console.log("Navigating to", currentUser ? "/skills" : "/onboarding");
                navigate(currentUser ? "/skills" : "/onboarding");
              }}
              onMouseEnter={() => setIsButtonHovered(true)}
              onMouseLeave={() => setIsButtonHovered(false)}
            >
              Start Training
            </button>
          </div>
        </div>

        <div style={styles.divider} />

        <div style={styles.sectionContainer}>
          <h2
            style={{
              ...styles.sectionTitle,
              ...(isMobile ? styles.sectionTitleLeftMobile : styles.sectionTitleLeft),
            }}
          >
            How It Works
          </h2>
          <div style={styles.card}>
            <div style={styles.stepNumber}>
              <span style={styles.stepNumberText}>1</span>
            </div>
            <p style={styles.stepText}>
              <strong>Select a Training Goal</strong> – Pick {'\'Sit\''}, {'\'Stay\''}, {'\'Leash Training\''}.
            </p>
          </div>
          <div style={styles.card}>
            <div style={styles.stepNumber}>
              <span style={styles.stepNumberText}>2</span>
            </div>
            <p style={styles.stepText}>
              <strong>Real-Time AI Coaching</strong> – Step-by-step guidance with feedback.
            </p>
          </div>
          <div style={styles.card}>
            <div style={styles.stepNumber}>
              <span style={styles.stepNumberText}>3</span>
            </div>
            <p style={styles.stepText}>
              <strong>Track Progress & Earn Rewards</strong> – Celebrate your {'\'dog\''}s milestones.
            </p>
          </div>
        </div>

        <div style={styles.divider} />

        {/* Section: Real-Time Training */}
        <div style={styles.sectionContainer}>
          <div style={isMobile ? styles.sectionRowMobile : styles.sectionRow}>
            <div style={styles.sectionIconContainer}>
              <div style={styles.sectionIconCircle}>
                <span style={styles.sectionIcon}>🤖</span>
              </div>
            </div>
            <div style={styles.sectionTextContainer}>
              <h2 style={styles.sectionTitle}>Real-Time Training</h2>
              <p style={styles.sectionText}>
                Get instant feedback and guidance while you train — no more guessing if you&apos;re doing it right. Doglingo uses AI to adapt in the moment, helping you correct behavior as it happens.
              </p>
            </div>
          </div>
        </div>

        <div style={styles.divider} />

        {/* Section: Fun and Engaging */}
        <div style={styles.sectionContainer}>
          <div style={isMobile ? styles.sectionRowMobile : styles.sectionRow}>
            <div style={styles.sectionIconContainer}>
              <div style={styles.sectionIconCircle}>
                <span style={styles.sectionIcon}>🎯</span>
              </div>
            </div>
            <div style={styles.sectionTextContainer}>
              <h2 style={styles.sectionTitle}>Fun and Engaging</h2>
              <p style={styles.sectionText}>
                Training shouldn&apos;t feel like a chore. With interactive games, playful prompts, and positive reinforcement, Doglingo makes learning fun for both you and your pup.
              </p>
            </div>
          </div>
        </div>

        <div style={styles.divider} />

        {/* Section: Personalized for Your Dog */}
        <div style={styles.sectionContainer}>
          <div style={isMobile ? styles.sectionRowMobile : styles.sectionRow}>
            <div style={styles.sectionIconContainer}>
              <div style={styles.sectionIconCircle}>
                <span style={styles.sectionIcon}>🐶</span>
              </div>
            </div>
            <div style={styles.sectionTextContainer}>
              <h2 style={styles.sectionTitle}>Personalized for Your Dog</h2>
              <p style={styles.sectionText}>
                Every dog is different. Doglingo tailors training based on your dog’s age, breed, personality, and progress, ensuring you get a program that actually works for your dog.
              </p>
            </div>
          </div>
        </div>

        <div style={styles.divider} />

        <div style={styles.ctaContainer}>
          <div style={styles.ctaTextContainer}>
            <h3 style={styles.ctaTitle}>🐶 Ready to Train Your Dog Smarter?</h3>
            <p style={styles.ctaSubtitle}>
              Get a personalized plan to train your dog with AI recommendations.
            </p>
          </div>
          <button
            style={getButtonStyle(styles.ctaButton, styles.ctaButtonHover, isButtonHovered)}
            onClick={() => {
              console.log("Navigating to", currentUser ? "/skills" : "/onboarding");
              navigate(currentUser ? "/skills" : "/onboarding");
            }}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
          >
            Start Training
          </button>
        </div>

        <div style={styles.footerDivider} />
        <footer style={styles.footer}>
          <div style={styles.footerLeft}>
            <img src={Logo} alt="Doglingo Logo" style={styles.footerLogo} />
            <div style={styles.socialIcons}>
              <a href="https://www.instagram.com/doglingoai/" target="_blank" rel="noopener noreferrer" style={styles.socialLink}>
                <svg style={styles.socialIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                </svg>
              </a>
              <a href="https://www.facebook.com/profile.php?id=61574414999269" target="_blank" rel="noopener noreferrer" style={styles.socialLink}>
                <svg style={styles.socialIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/doglingo/" target="_blank" rel="noopener noreferrer" style={styles.socialLink}>
                <svg style={styles.socialIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                  <rect x="2" y="9" width="4" height="12" />
                  <circle cx="4" cy="4" r="2" />
                </svg>
              </a>
            </div>
          </div>
          <div style={styles.footerRight}>
            <a href="mailto:hello@doglingo.com" style={styles.footerLink}>Contact Us</a>
            <button onClick={() => navigate('/terms-of-service')} style={styles.footerLinkButton}>
              <span style={styles.footerLink}>Terms of Service</span>
            </button>
            <button onClick={() => navigate('/privacy-policy')} style={styles.footerLinkButton}>
              <span style={styles.footerLink}>Privacy Policy</span>
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "0px",
    minHeight: "100vh",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: isMobile ? "15px" : "20px",
    flex: 1,
  },
  heroContainer: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: isMobile ? "center" : "space-between",
    padding: "20px 0",
    width: "100%",
  },
  heroImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: isMobile ? "100%" : "50%",
    paddingRight: isMobile ? 0 : "20px",
    marginBottom: isMobile ? "20px" : 0,
  },
  imageWrapper: {
    width: "100%",
    maxWidth: 500,
    height: isMobile ? 300 : 400,
    borderRadius: "10px",
    overflow: "hidden",
  },
  heroImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  heroTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: isMobile ? "center" : "flex-start",
    width: isMobile ? "100%" : "50%",
    paddingLeft: isMobile ? 0 : "20px",
    paddingTop: isMobile ? 0 : 0,
  },
  heroTitle: {
    fontFamily: "MouseMemoirs, sans-serif",
    fontSize: isMobile ? "32px" : "48px",
    fontWeight: "bold",
    color: "#2D3748",
    letterSpacing: "1px",
    textAlign: isMobile ? "center" : "left",
    marginBottom: "10px",
  },
  heroSubtitle: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#333",
    lineHeight: "25.5px",
    marginTop: "10px",
    textAlign: isMobile ? "center" : "left",
    maxWidth: "500px",
  },
  buttonBlue: {
    backgroundColor: "#007BFF",
    padding: "14px 25px",
    borderRadius: "50px",
    alignItems: "center",
    width: isMobile ? "80%" : "200px",
    marginTop: "10px",
    border: "none",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
    cursor: "pointer",
    display: "block",
    marginLeft: isMobile ? "auto" : 0,
    marginRight: isMobile ? "auto" : 0,
  },
  buttonBlueHover: {
    backgroundColor: "#0056b3",
  },
  divider: {
    height: "1px",
    backgroundColor: "#E2E8F0",
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%",
  },
  sectionContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  sectionRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
  },
  sectionRowMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
  },
  sectionIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionIconCircle: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    backgroundColor: "#3182CE",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionIcon: {
    fontSize: "54px",
    color: "#fff",
  },
  sectionTextContainer: {
    flex: 1,
    textAlign: isMobile ? "center" : "left",
  },
  sectionTitle: {
    fontFamily: "MouseMemoirs, sans-serif",
    fontSize: isMobile ? "22px" : "32px",
    fontWeight: "bold",
    color: "#2D3748",
    marginBottom: "10px",
    letterSpacing: "1px",
  },
  sectionText: {
    fontSize: "16px",
    color: "#333",
    lineHeight: "24px",
    maxWidth: "600px",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F7FAFC",
    borderRadius: "10px",
    padding: "12px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
    marginBottom: "10px",
  },
  stepNumber: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    backgroundColor: "#38A169",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  stepNumberText: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
  },
  stepText: {
    fontSize: "16px",
    color: "#333",
    lineHeight: "24px",
    flex: 1,
  },
  ctaContainer: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: isMobile ? "center" : "space-between",
    padding: isMobile ? "20px" : "20px 40px", // Added left/right padding for web
    gap: isMobile ? "15px" : "0",
    backgroundColor: "#007BFF",
    borderRadius: "10px",
  },
  ctaTextContainer: {
    flex: isMobile ? "0" : "2",
    alignItems: isMobile ? "center" : "flex-start",
  },
  ctaTitle: {
    fontFamily: "MouseMemoirs, sans-serif",
    fontSize: isMobile ? "28px" : "40px",
    fontWeight: "600",
    color: "#fff",
    paddingTop: "8px",
    paddingBottom: "8px",
    letterSpacing: "1px",
    textAlign: isMobile ? "center" : "left",
  },
  ctaSubtitle: {
    fontSize: "16px",
    color: "#fff",
    lineHeight: "24px",
    marginTop: "5px",
    marginBottom: "5px",
    textAlign: isMobile ? "center" : "left",
  },
  ctaButton: {
    backgroundColor: "#fff",
    padding: "14px 25px",
    borderRadius: "50px",
    alignItems: "center",
    width: isMobile ? "80%" : "200px",
    marginTop: "10px",
    border: "none",
    color: "#007BFF",
    fontSize: "18px",
    fontWeight: "bold",
    cursor: "pointer",
    display: "block",
    marginLeft: isMobile ? "auto" : 0,
    marginRight: isMobile ? "auto" : 0,
  },
  ctaButtonHover: {
    backgroundColor: "#e6f0fa",
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#E2E8F0",
    marginTop: "40px",
    marginBottom: "40px",
    width: "100%",
  },
  footer: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 0",
    width: "100%",
    backgroundColor: "#fff",
  },
  footerLeft: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    gap: isMobile ? "15px" : "20px",
    marginBottom: isMobile ? "20px" : 0,
  },
  footerLogo: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
  },
  socialIcons: {
    display: "flex",
    gap: "15px",
  },
  socialLink: {
    color: "#666",
    textDecoration: "none",
  },
  socialIcon: {
    width: "24px",
    height: "24px",
    strokeWidth: "2",
  },
  footerRight: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    gap: isMobile ? "10px" : "20px",
  },
  footerLinkButton: {
    background: "none",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  footerLink: {
    fontSize: "16px",
    color: "#666",
    textDecoration: "none",
  },
};

export default MainScreen;