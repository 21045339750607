import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, collection, getDocs, getDoc, query, orderBy } from "firebase/firestore";

const SkillsScreen = () => {
  const navigate = useNavigate();
  const [skillsData, setSkillsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [unlockedSkill, setUnlockedSkill] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [dogs, setDogs] = useState([]);
  const [ratings, setRatings] = useState({});

  const categoryMappings = useMemo(() => ({
    'basic_commands___cues': 'Basic Skills',
    'behavioral_training_&_social_skills': 'Behavioral Skills',
    'advanced_obedience_&_manners': 'Advanced Skills',
    'tricks_&_fun_commands': 'Fun Skills'
  }), []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (!user) {
        console.log('User not logged in, redirecting to home');
        navigate('/');
      } else {
        fetchUserData(user.uid);
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const fetchUserData = async (uid) => {
    try {
      const userDocRef = doc(firestore, 'users', uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setIsSubscribed(data.isSubscribed || false);

        const dogsRef = collection(firestore, 'users', uid, 'dogs');
        const dogsSnapshot = await getDocs(dogsRef);
        const dogsList = dogsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Fetched dogs:', dogsList);
        setDogs(dogsList);

        if (dogsList.length > 0) {
          const firstDogId = dogsList[0].id;
          const ratingsRef = collection(firestore, 'users', uid, 'dogs', firstDogId, 'skillRatings');
          const ratingsSnapshot = await getDocs(ratingsRef);
          const ratingsData = {};
          ratingsSnapshot.forEach(doc => {
            const rating = doc.data();
            ratingsData[rating.skillId] = rating.rating || 0;
          });
          setRatings(ratingsData);

          if (!ratingsSnapshot.empty) {
            const firstRating = ratingsSnapshot.docs[0].data();
            setUnlockedSkill({ 
              category: firstRating.categoryId, 
              skillId: firstRating.skillId, 
              skillName: firstRating.skillName 
            });
          }
        }
      }
    } catch (err) {
      console.error('Error fetching user data:', err.message);
      setError('Error loading user data: ' + err.message);
    }
  };

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        setLoading(true);
        const skillsRef = collection(firestore, 'skills');
        const skillsSnapshot = await getDocs(query(skillsRef, orderBy('order')));
        const skillsDataTemp = {};

        for (const categoryDoc of skillsSnapshot.docs) {
          const categoryName = categoryDoc.id;
          const skillsQuery = collection(firestore, `skills/${categoryName}/skills`);
          const skillsSnapshot = await getDocs(query(skillsQuery, orderBy('order')));

          skillsDataTemp[categoryName] = {
            order: categoryDoc.data().order || 0,
            skills: skillsSnapshot.docs.map(skillDoc => ({ id: skillDoc.id, ...skillDoc.data() }))
          };
        }

        const renamedSkillsData = {};
        Object.keys(categoryMappings).forEach((firestoreKey) => {
          const displayName = categoryMappings[firestoreKey];
          renamedSkillsData[displayName] = skillsDataTemp[firestoreKey] || { skills: [] };
        });
        console.log('Fetched skillsData:', renamedSkillsData);
        setSkillsData(renamedSkillsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching skills:', err.message);
        setError('Failed to load skills: ' + err.message);
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchSkills();
    }
  }, [currentUser, categoryMappings]);

  const generatePersonalizedPlan = () => {
    console.log('Generating plan - Dogs:', dogs, 'SkillsData:', skillsData);
    if (!dogs.length || !Object.keys(skillsData).length) {
      console.log('No dogs or skills data available');
      return [];
    }

    const dog = dogs[0];
    const recommendedSkills = [];
    const allSkills = Object.values(skillsData).flatMap(category => category.skills);
    console.log('All available skills:', allSkills);

    const addSkill = (skillName, reason, category) => {
      const skill = allSkills.find(s => s.name.toLowerCase() === skillName.toLowerCase());
      if (skill && !recommendedSkills.some(rs => rs.skill.id === skill.id)) {
        console.log(`Adding skill: ${skillName} - Reason: ${reason}`);
        recommendedSkills.push({ skill, reason });
      } else {
        console.log(`Skill not found or already added: ${skillName}`);
      }
    };

    if (dog.pottyTrained === "No") {
      addSkill("wait", "For potty training (wait at door)", "basic_commands___cues");
    }

    const behaviorMap = {
      "Aggression": ["settle", "leave_it"],
      "Separation Anxiety": ["stay", "settle"],
      "Excessive Barking": ["quiet", "speak"],
      "Jumping": ["off", "sit"],
      "Chewing": ["leave_it", "drop_it"],
      "Pulling on leash": ["leash_walking"],
      "Counter-surfing": ["no_counter-surfing"],
      "Shyness": ["accept_handling", "greet_politely"]
    };
    dog.behavioralIssues.forEach(issue => {
      const skillsForIssue = behaviorMap[issue] || [];
      skillsForIssue.forEach(skillName => addSkill(skillName, `To address ${issue.toLowerCase()}`, "behavioral_training_&_social_skills"));
    });

    const basicSkills = ["sit", "stay", "come"];
    basicSkills.forEach(skillName => {
      if (!dog.skills.includes(skillName)) {
        addSkill(skillName, "Essential basic skill", "basic_commands___cues");
      }
    });

    const goalMap = {
      "Obedience (e.g., sit, stay)": ["heel", "wait"],
      "Behavior (e.g., less barking)": ["quiet", "impulse_control"],
      "Fun tricks (e.g., roll over)": ["roll_over", "fetch"],
      "Social skills (e.g., greeting politely)": ["greet_politely", "accept_handling"]
    };
    if (recommendedSkills.length < 5 && dog.trainingGoal && goalMap[dog.trainingGoal]) {
      goalMap[dog.trainingGoal].forEach(skillName => {
        if (!dog.skills.includes(skillName)) {
          addSkill(skillName, `Matches your goal: ${dog.trainingGoal.toLowerCase()}`, "basic_commands___cues");
        }
      });
    }

    const breedMap = {
      "Labrador Retriever": ["fetch", "spin"],
      "Border Collie": ["find_it", "place"],
      "Bulldog": ["sit", "shake"]
    };
    if (recommendedSkills.length < 5 && breedMap[dog.dogBreed]) {
      breedMap[dog.dogBreed].forEach(skillName => {
        if (!dog.skills.includes(skillName)) {
          addSkill(skillName, `Great for ${dog.dogBreed}s`, "tricks_&_fun_commands");
        }
      });
    }

    const ageActivityMap = {
      "Puppy (Less than a year old)": {
        "Very active (loves running)": ["fetch", "sit"],
        "Moderately active": ["accept_handling", "down"],
        "Calm (prefers relaxing)": ["sit", "settle"]
      },
      "Young (1 - 3 Years)": {
        "Very active (loves running)": ["roll_over", "high_five"],
        "Moderately active": ["heel", "spin"],
        "Calm (prefers relaxing)": ["stay", "watch_me"]
      },
      "Adult (3 - 7 Years)": {
        "Very active (loves running)": ["fetch", "leash_walking"],
        "Moderately active": ["heel", "wait"],
        "Calm (prefers relaxing)": ["settle", "place"]
      },
      "Senior (7+ Years)": {
        "Very active (loves running)": ["shake", "bow"],
        "Moderately active": ["touch", "stand"],
        "Calm (prefers relaxing)": ["settle", "quiet"]
      }
    };
    if (recommendedSkills.length < 6 && ageActivityMap[dog.dogAge] && ageActivityMap[dog.dogAge][dog.activityLevel]) {
      ageActivityMap[dog.dogAge][dog.activityLevel].forEach(skillName => {
        if (!dog.skills.includes(skillName)) {
          addSkill(skillName, `Suits ${dog.dogAge.toLowerCase()} and ${dog.activityLevel.toLowerCase()} dogs`, "basic_commands___cues");
        }
      });
    }

    if (recommendedSkills.length < 6 && basicSkills.every(skill => dog.skills.includes(skill))) {
      addSkill("spin", "A fun trick to enjoy", "tricks_&_fun_commands");
    }

    console.log('Generated plan:', recommendedSkills);
    return recommendedSkills.slice(0, 6);
  };

  const handleSkillPress = async (skill, categoryName) => {
    const firestoreCategoryKey = Object.keys(categoryMappings).find(
      (key) => categoryMappings[key] === categoryName
    ) || skill.category;

    if (!firestoreCategoryKey) {
      console.error('No matching Firestore category found for:', categoryName);
      return;
    }

    const skillData = { category: firestoreCategoryKey, skillId: skill.id };

    if (isSubscribed) {
      navigateToTrainer(skillData, skill.name);
    } else if (!unlockedSkill && dogs.length > 0) {
      const firstDogId = dogs[0].id;
      await setDoc(
        doc(firestore, 'users', currentUser.uid, 'dogs', firstDogId, 'skillRatings', skill.id),
        { 
          categoryId: firestoreCategoryKey, 
          skillId: skill.id, 
          skillName: skill.name,
          rating: 0,
          badgeEarned: false,
          timestamp: new Date().toISOString()
        },
        { merge: true }
      );
      setUnlockedSkill({ category: firestoreCategoryKey, skillId: skill.id, skillName: skill.name });
      navigateToTrainer(skillData, skill.name);
    } else if (
      unlockedSkill?.category === firestoreCategoryKey &&
      unlockedSkill?.skillId === skill.id
    ) {
      navigateToTrainer(skillData, skill.name);
    } else {
      navigate('/subscription');
    }
  };

  const navigateToTrainer = (skillData, skillName) => {
    navigate(
      `/trainer?category=${skillData.category}&skill_id=${skillData.skillId}&skill_name=${encodeURIComponent(skillName)}`
    );
  };

  const [hoveredSkill, setHoveredSkill] = useState(null);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} style={{ color: i <= rating ? '#FFD700' : '#ccc', fontSize: '16px' }}>
          ★
        </span>
      );
    }
    return stars;
  };

  const renderSkillItem = ({ item: skill, categoryName }) => (
    <div style={styles.skillItem}>
      <button
        style={{
          ...styles.skillCircle,
          ...(hoveredSkill === skill.id ? styles.skillCircleHover : {})
        }}
        onMouseEnter={() => setHoveredSkill(skill.id)}
        onMouseLeave={() => setHoveredSkill(null)}
        onClick={() => handleSkillPress(skill, categoryName)}
      >
        <span style={styles.skillIcon}>{skill.icon || '🐶'}</span>
        <span style={styles.skillLabel}>{skill.name}</span>
      </button>
      <p style={styles.skillDesc}>{skill.description || 'No description available'}</p>
      {ratings[skill.id] !== undefined && (
        <div style={styles.ratingRow}>
          {renderStars(ratings[skill.id])}
        </div>
      )}
    </div>
  );

  const renderPersonalizedPlan = () => {
    const plan = generatePersonalizedPlan();
    console.log('Rendering plan:', plan);
    if (!plan.length) {
      return <p>No personalized plan available yet. Complete your dog’s profile or skills data to see recommendations.</p>;
    }

    return (
      <div style={styles.personalizedPlanContainer}>
        <h2 style={styles.personalizedPlanTitle}>Personalized Training Plan</h2>
        <p style={styles.personalizedPlanSubtitle}>
          Tailored for {dogs[0]?.dogName} based on their needs, goals, breed, age, and activity level
        </p>
        <div style={styles.planGrid}>
          {plan.map(({ skill, reason }, index) => (
            <div key={index} style={styles.planItem}>
              <button
                style={{
                  ...styles.planSkillCircle,
                  ...(hoveredSkill === skill.id ? styles.skillCircleHover : {}),
                  ...(ratings[skill.id] !== undefined ? styles.planSkillCircleProgress : {})
                }}
                onMouseEnter={() => setHoveredSkill(skill.id)}
                onMouseLeave={() => setHoveredSkill(null)}
                onClick={() => handleSkillPress(skill, categoryMappings[skill.category] || "Basic Skills")}
              >
                <span style={styles.skillIcon}>{skill.icon || '🐾'}</span>
                <span style={styles.skillLabel}>{skill.name}</span>
              </button>
              <p style={styles.planReason}>{reason}</p>
              {ratings[skill.id] !== undefined && (
                <div style={styles.planProgress}>{renderStars(ratings[skill.id])}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getSubtitle = () => {
    if (isSubscribed && dogs.length > 0) {
      return `Just 5 to 10 minutes a day will make great progress long term with ${dogs[0].dogName}!`;
    } else if (unlockedSkill && dogs.length > 0) {
      return [
        <br key="line-break" />,
        `You've unlocked your free skill! Have fun training ${dogs[0].dogName}!`,
        <br key="line-break" />,
        <br key="line-break" />,
        `Your unlocked free skill is: ${unlockedSkill.skillName}`
      ];
    } else {
      return "Your first skill is on us, choose any skill you're interested in for free.";
    }
  };

  if (!currentUser) return null;
  if (loading) return <p style={styles.loading}>Fetching skills...</p>;
  if (error) return <p style={styles.error}>{error}</p>;

  return (
    <div style={styles.container}>
      <div style={styles.heroSection}>
        <h1 style={styles.heroTitle}>Learn the lingo!</h1>
        <p style={styles.heroSubtitle}>{getSubtitle()}</p>
      </div>
      <div style={styles.contentWrapper}>
        {renderPersonalizedPlan()}
        <hr style={styles.sectionDivider} />
        {Object.entries(skillsData).map(([categoryName, categoryData], index) => (
          <React.Fragment key={categoryName}>
            <div style={styles.categoryContainer}>
              <h2 style={styles.categoryTitle}>{categoryName}</h2>
              <div style={styles.webCategoryLayout}>
                {categoryData?.skills.length > 0 ? (
                  categoryData.skills.map((skill) => (
                    <div key={skill.id}>
                      {renderSkillItem({ item: skill, categoryName })}
                    </div>
                  ))
                ) : (
                  <p style={styles.noSkillsText}>No skills available</p>
                )}
              </div>
            </div>
            {index < Object.entries(skillsData).length - 1 && <hr style={styles.sectionDivider} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: { display: 'flex', flexDirection: 'column', backgroundColor: '#fff' },
  heroSection: { padding: '50px', backgroundColor: '#f8f9fa', textAlign: 'center' },
  heroTitle: { fontSize: '36px', fontWeight: 'bold', color: '#333', marginBottom: '10px' },
  heroSubtitle: { fontSize: '18px', color: '#555' },
  contentWrapper: { padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' },
  categoryContainer: { marginTop: '50px', marginBottom: '50px', textAlign: 'center', width: '100%' },
  categoryTitle: { fontSize: '28px', fontWeight: 'bold', marginBottom: '25px' },
  webCategoryLayout: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: '40px' },
  skillItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '40px',
    width: '250px'
  },
  skillCircle: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: '2px solid #ddd',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border 0.2s ease-in-out',
    padding: '10px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  skillCircleHover: {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    border: '2px solid #ccc'
  },
  skillIcon: {
    fontSize: '75px',
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  skillLabel: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '1.2'
  },
  skillDesc: {
    fontSize: '16px',
    color: '#555',
    marginTop: '12px',
    textAlign: 'center',
    maxWidth: '250px'
  },
  ratingRow: {
    marginTop: '8px',
    textAlign: 'center'
  },
  loading: { fontSize: '20px', textAlign: 'center', marginTop: '30px' },
  error: { fontSize: '20px', color: 'red', textAlign: 'center', marginTop: '30px' },
  noSkillsText: { fontSize: '18px', color: '#666', textAlign: 'center' },
  personalizedPlanContainer: {
    marginBottom: '50px',
    marginTop: '50px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '1200px'
  },
  personalizedPlanTitle: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '10px'
  },
  personalizedPlanSubtitle: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '50px'
  },
  planGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '40px'
  },
  planItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '250px',
    position: 'relative'
  },
  planSkillCircle: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    backgroundColor: '#f8f9fa',
    border: '2px solid #38A169',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    padding: '10px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  planSkillCircleProgress: {
    border: '2px solid #FFD700',
    backgroundColor: '#fff8e1'
  },
  planReason: {
    fontSize: '14px',
    color: '#777',
    marginTop: '10px'
  },
  planProgress: {
    marginTop: '8px',
    textAlign: 'center'
  },
  sectionDivider: {
    border: 'none',
    borderTop: '1px solid #d3d3d3', // Light grey color
    width: '80%',
    margin: '20px auto',
  }
};

export default SkillsScreen;