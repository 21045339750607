import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.js'; // Added .js extension

class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1 style={{ fontSize: '24px', color: '#d32f2f' }}>Oops! Something went wrong.</h1>
          <p style={{ fontSize: '16px', color: '#666' }}>
            {this.state.error?.message || "An unexpected error occurred."}
          </p>
          <button
            style={{
              padding: '10px 20px',
              backgroundColor: '#007BFF',
              color: '#fff',
              borderRadius: '8px',
              border: 'none',
              cursor: 'pointer',
              marginTop: '20px'
            }}
            onClick={() => window.location.reload()}
          >
            Refresh Page
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);