import React from "react";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, auth } from "../firebase.js";

const TestUpload = () => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        try {
          const user = auth.currentUser;
          if (!user) throw new Error("No authenticated user");
          console.log("Authenticated user ID:", user.uid);
          const token = await user.getIdToken();
          console.log("User token:", token);

          const storageRef = ref(storage, `testImages/${user.uid}/${file.name}`);
          console.log("Storage ref:", storageRef.fullPath);
          console.log("Storage bucket:", storage.app.options.storageBucket);

          const metadata = {
            contentType: file.type,
            customMetadata: { firebaseToken: token },
          };
          console.log("Uploading with metadata:", metadata);

          const uploadResult = await uploadBytes(storageRef, file, metadata);
          console.log("Upload result:", uploadResult);

          const url = await getDownloadURL(storageRef);
          console.log("Image uploaded successfully, URL:", url);
        } catch (err) {
          console.error("Upload error:", err);
          console.log("Error code:", err.code);
          console.log("Error message:", err.message);
          console.log("Server response:", err.serverResponse);
        }
      }
    },
  });

  return (
    <div>
      <button {...getRootProps()}>Upload Test Image<input {...getInputProps()} /></button>
    </div>
  );
};

export default TestUpload;