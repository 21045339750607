import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import defaultDogImage from "../assets/landing-page-graphic-AI-Dog-Trainer.webp";

// Sample icons for skills
const skillIcons = {
  "Sit on a Surfboard": "🏄",
  "Put Toys Away": "🧺",
  "Shake Paw": "🤝",
  "Ring a Bell to Go Outside": "🛎️",
  "Spin in a Circle": "🪩",
  "Close the Door": "🚪",
};

// Value snapshot icons
const valueIcons = {
  "Smart AI-powered training": "🧠",
  "Real-time progress tracking": "📈",
  "Custom trick ideas": "💡",
  "Positive reinforcement techniques": "🎓",
  "Train through with AI on your side": "🤖",
  "Mobile & web access": "📱",
};

// Sample recommended skills
const recommendedSkills = [
  { name: "Sit & Stay", icon: "🧘" },
  { name: "Shake Paw", icon: "🤝" },
  { name: "Spin in a Circle", icon: "🪩" },
];

// Updated training journey timeline (months instead of weeks)
const trainingJourney = [
  { month: "Month 1", skill: "Basic Skills" },
  { month: "Month 2", skill: "Recall Games" },
  { month: "Month 3", skill: "Fun Tricks like High Five!" },
  { month: "Month 4", skill: "Impulse Control at the Door" },
];

const DogDashboard = () => {
  const navigate = useNavigate();
  const [dogData, setDogData] = useState(null);
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const [loading, setLoading] = useState(true);

  // Fetch user and dog data from Firestore
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const dogsCollectionRef = collection(firestore, `users/${currentUser.uid}/dogs`);
          const dogQuerySnapshot = await getDocs(dogsCollectionRef);
          if (!dogQuerySnapshot.empty) {
            const dogDoc = dogQuerySnapshot.docs[0];
            setDogData(dogDoc.data());
          } else {
            console.error("No dog data found!");
            navigate("/onboarding");
          }
        } catch (error) {
          console.error("Error fetching dog data:", error);
          navigate("/onboarding");
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  // Simulate confetti or animation on first login
  useEffect(() => {
    if (isFirstLogin && dogData) {
      setTimeout(() => setIsFirstLogin(false), 3000);
    }
  }, [dogData, isFirstLogin]); // Added isFirstLogin to dependency array

  const getStyles = () => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "#f9fafb",
      padding: "20px",
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "1200px",
      padding: "0 10px",
      boxSizing: "border-box",
    },
    heroSection: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "30px",
      textAlign: "center",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      marginBottom: "60px",
      width: "100%",
    },
    welcomeText: {
      fontSize: window.innerWidth < 768 ? "24px" : "32px",
      fontWeight: "bold",
      color: "#333",
      marginBottom: "15px",
    },
    dogAvatar: {
      width: window.innerWidth < 768 ? "120px" : "150px",
      height: window.innerWidth < 768 ? "120px" : "150px",
      borderRadius: "50%",
      objectFit: "cover",
      border: "3px solid #38A169",
      animation: isFirstLogin ? "wag 1s ease-in-out infinite" : "none",
    },
    dogStats: {
      display: "flex",
      flexDirection: window.innerWidth < 768 ? "column" : "row",
      justifyContent: "center",
      gap: window.innerWidth < 768 ? "10px" : "20px",
      marginTop: "15px",
      fontSize: "16px",
      color: "#666",
    },
    skillSection: {
      marginBottom: "60px",
      width: "100%",
    },
    skillHeader: {
      fontSize: window.innerWidth < 768 ? "24px" : "32px",
      fontWeight: "bold",
      color: "#333",
      textAlign: "center",
      marginBottom: "15px",
    },
    skillSubheader: {
      fontSize: "16px",
      color: "#666",
      textAlign: "center",
      marginBottom: "20px",
    },
    skillGrid: {
      display: "grid",
      gridTemplateColumns: window.innerWidth < 768 ? "1fr" : "repeat(3, 1fr)",
      gap: "20px",
    },
    skillCard: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "20px",
      textAlign: "center",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
    skillCardHover: {
      transform: "translateY(-5px)",
    },
    skillIcon: {
      fontSize: "40px",
      marginBottom: "10px",
    },
    skillName: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#333",
      marginBottom: "10px",
    },
    skillButton: {
      backgroundColor: "#38A169",
      color: "#fff",
      border: "none",
      padding: "8px 16px",
      borderRadius: "50px",
      cursor: "pointer",
      fontSize: "14px",
      margin: "5px",
    },
    valueSection: {
      marginBottom: "60px",
      width: "100%",
    },
    valueHeader: {
      fontSize: window.innerWidth < 768 ? "24px" : "28px",
      fontWeight: "bold",
      color: "#333",
      textAlign: "center",
      marginBottom: "20px",
    },
    valueGrid: {
      display: "grid",
      gridTemplateColumns: window.innerWidth < 768 ? "1fr" : "repeat(3, 1fr)",
      gap: "20px",
    },
    valueCard: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "20px",
      textAlign: "center",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    },
    valueIcon: {
      fontSize: "40px",
      marginBottom: "10px",
    },
    valueText: {
      fontSize: "16px",
      color: "#333",
    },
    aiSection: {
      marginBottom: "60px",
      width: "100%",
    },
    aiHeader: {
      fontSize: window.innerWidth < 768 ? "24px" : "28px",
      fontWeight: "bold",
      color: "#333",
      textAlign: "center",
      marginBottom: "20px",
    },
    aiCard: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "20px",
      textAlign: "center",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    },
    aiIcon: {
      fontSize: "40px",
      marginBottom: "10px",
    },
    aiText: {
      fontSize: "16px",
      color: "#333",
      marginBottom: "15px",
    },
    ctaSection: {
      textAlign: "center",
      marginBottom: "60px",
      width: "100%",
    },
    ctaButton: {
      backgroundColor: "#38A169",
      color: "#fff",
      border: "none",
      padding: "15px 30px",
      borderRadius: "50px",
      fontSize: "18px",
      cursor: "pointer",
      transition: "transform 0.2s",
    },
    ctaButtonHover: {
      transform: "scale(1.05)",
    },
    recommendedSkills: {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
      marginTop: "20px",
      flexWrap: "wrap",
    },
    recommendedCard: {
      backgroundColor: "#f0f0f0",
      borderRadius: "8px",
      padding: "10px",
      textAlign: "center",
      width: window.innerWidth < 768 ? "120px" : "150px",
    },
    timelineSection: {
      marginBottom: "60px",
      width: "100%",
    },
    timelineHeader: {
      fontSize: window.innerWidth < 768 ? "24px" : "28px",
      fontWeight: "bold",
      color: "#333",
      textAlign: "center",
      marginBottom: "20px",
    },
    timeline: {
      display: "flex",
      flexDirection: window.innerWidth < 768 ? "column" : "row",
      justifyContent: "center",
      gap: "20px",
    },
    timelineItem: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      padding: "15px",
      textAlign: "center",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      width: window.innerWidth < 768 ? "100%" : "200px",
    },
    didYouKnowSection: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "20px",
      textAlign: "center",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "60px",
      width: "100%",
    },
    didYouKnowHeader: {
      fontWeight: "bold",
      color: "#333",
      marginBottom: "10px",
    },
    didYouKnowText: {
      fontSize: "16px",
      color: "#666",
    },
  });

  const styles = getStyles();
  const [hoveredSkill, setHoveredSkill] = useState(null);
  const [hoveredCta, setHoveredCta] = useState(false);

  if (loading) return <div>Loading...</div>;
  if (!dogData) return null;

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        {/* 1. Welcome Hero Section */}
        <div style={styles.heroSection}>
          <h1 style={styles.welcomeText}>
            {dogData.dogName} is ready to learn!
          </h1>
          <img
            src={dogData.dogImage || defaultDogImage}
            alt={`${dogData.dogName}`}
            style={styles.dogAvatar}
          />
          <div style={styles.dogStats}>
            <span>Breed: {dogData.dogBreed}</span>
            <span>Age: {dogData.dogAge}</span>
            <span>Level: Beginner</span>
          </div>
        </div>

        {/* 2. Skill Potential Highlight */}
        <div style={styles.skillSection}>
          <h2 style={styles.skillHeader}>
            Teach {dogData.dogName} Almost Anything
          </h2>
          <p style={styles.skillSubheader}>
            With the power of AI, unlock an endless library of personalized dog training.
          </p>
          <div style={styles.skillGrid}>
            {Object.keys(skillIcons).map((skill, index) => (
              <div
                key={index}
                style={{
                  ...styles.skillCard,
                  ...(hoveredSkill === index && styles.skillCardHover),
                }}
                onMouseEnter={() => setHoveredSkill(index)}
                onMouseLeave={() => setHoveredSkill(null)}
              >
                <div style={styles.skillIcon}>{skillIcons[skill]}</div>
                <div style={styles.skillName}>{skill}</div>
              </div>
            ))}
          </div>
        </div>

        {/* 3. Value Snapshot Section */}
        <div style={styles.valueSection}>
          <h2 style={styles.valueHeader}>What You Get</h2>
          <div style={styles.valueGrid}>
            {Object.keys(valueIcons).map((value, index) => (
              <div key={index} style={styles.valueCard}>
                <div style={styles.valueIcon}>{valueIcons[value]}</div>
                <p style={styles.valueText}>{value}</p>
              </div>
            ))}
          </div>
        </div>

        {/* 4. AI Smartest Dog Trainer Section */}
        <div style={styles.aiSection}>
          <h2 style={styles.aiHeader}>The Smartest Dog Trainer at Your Fingertips</h2>
          <div style={styles.aiCard}>
            <div style={styles.aiIcon}>🤖</div>
            <p style={styles.aiText}>
              Harness the power of AI, the world’s smartest dog trainer, to guide you and {dogData.dogName} through personalized training plans, anytime, anywhere.
            </p>
          </div>
        </div>

        {/* 5. Start Training Today CTA */}
        <div style={styles.ctaSection}>
          <button
            style={{
              ...styles.ctaButton,
              ...(hoveredCta && styles.ctaButtonHover),
            }}
            onMouseEnter={() => setHoveredCta(true)}
            onMouseLeave={() => setHoveredCta(false)}
            onClick={() => navigate("/skills")}
          >
            View Personalized Training Plan
          </button>
          
        </div>

        {/* 6. Training Journey Timeline */}
        <div style={styles.timelineSection}>
          <h2 style={styles.timelineHeader}>Your Training Journey</h2>
          <div style={styles.timeline}>
            {trainingJourney.map((item, index) => (
              <div key={index} style={styles.timelineItem}>
                <p style={{ fontWeight: "bold" }}>{item.month}</p>
                <p>{item.skill}</p>
              </div>
            ))}
          </div>
        </div>

        {/* 7. Did You Know? */}
        <div style={styles.didYouKnowSection}>
          <h2 style={styles.didYouKnowHeader}>Did You Know?</h2>
          <p style={styles.didYouKnowText}>
            {dogData.dogBreed === "Labrador Retriever"
              ? "Labrador Retrievers are known for their intelligence and love for learning new tricks!"
              : `The ${dogData.dogBreed} breed is great at learning through positive reinforcement!`}
          </p>
        </div>
      </div>

      <style>
        {`
          @keyframes wag {
            0% { transform: rotate(0deg); }
            25% { transform: rotate(5deg); }
            75% { transform: rotate(-5deg); }
            100% { transform: rotate(0deg); }
          }
        `}
      </style>
    </div>
  );
};

export default DogDashboard;