import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../firebase.js";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, collection, getDocs } from "firebase/firestore"; // Added Firestore imports
import Logo from "../assets/DogTrainerAI.png";

const NavBar = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [dogImage, setDogImage] = useState(null); // State for dog's image
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown visibility

  // Detect screen resizing
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check authentication state and fetch dog image
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        // Fetch the first dog's image from Firestore
        try {
          const dogsRef = collection(firestore, "users", user.uid, "dogs");
          const dogsSnapshot = await getDocs(dogsRef);
          if (!dogsSnapshot.empty) {
            const firstDog = dogsSnapshot.docs[0].data();
            setDogImage(firstDog.dogImage || null); // Use dogImage if available, else null
          }
        } catch (error) {
          console.error("Error fetching dog image:", error.message);
        }
      } else {
        setDogImage(null); // Clear image if no user
      }
    });
    return unsubscribe;
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleEditProfile = () => {
    setDropdownOpen(false);
    navigate("/edit-profile"); // Assuming an edit profile route exists
  };

  return (
    <nav style={styles.navbar} role="navigation">
      <div style={styles.contentContainer}>
        <button
          style={styles.logoButton}
          onClick={() => navigate("/")}
          aria-label="Go to Home"
        >
          <img src={Logo} style={styles.logo} alt="DoglingoAI Logo" />
          <span style={styles.logoText}>Doglingo</span>
        </button>

        {/* Navigation Links */}
        <div style={isMobile ? styles.navLinksMobile : styles.navLinks}>
          <button
            style={styles.navLinkContainer}
            onClick={() => navigate(currentUser ? "/skills" : "/onboarding")}
            aria-label={currentUser ? "Train" : "Start"}
          >
            <span style={styles.navLink}>
              {currentUser ? "Train" : "Start"}
            </span>
          </button>

          {currentUser ? (
            <>
              {/* Profile Circle */}
              <button
                style={styles.profileCircle}
                onClick={toggleDropdown}
                aria-label="User Profile"
              >
                <img
                  src={dogImage || "https://placehold.co/40x40?text=Dog"}
                  style={styles.profileImage}
                  alt="Dog Profile"
                />
              </button>

              {/* Dropdown Menu */}
              {dropdownOpen && (
                <div style={isMobile ? styles.dropdownMobile : styles.dropdown}>
                  <button
                    style={styles.dropdownItem}
                    onClick={handleEditProfile}
                    aria-label="Edit Profile"
                  >
                    Edit Profile
                  </button>
                  <button
                    style={styles.dropdownItem}
                    onClick={handleLogout}
                    aria-label="Logout"
                  >
                    Logout
                  </button>
                </div>
              )}
            </>
          ) : (
            <button
              style={styles.navLinkContainer}
              onClick={() => navigate("/login")}
              aria-label="Login"
            >
              <span style={styles.navLink}>Login</span>
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

// Styles
const styles = {
  navbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 20px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #E2E8F0",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    width: "100%",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
  },
  logoButton: {
    display: "flex",
    alignItems: "center",
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: 0,
  },
  logo: {
    width: "50px",
    height: "auto",
    objectFit: "contain",
  },
  logoText: {
    fontSize: "32px",
    fontFamily: "MouseMemoirs, sans-serif",
    color: "#2D3748",
    marginLeft: "10px",
    fontWeight: "600",
  },
  navLinks: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    position: "relative", // For dropdown positioning
  },
  navLinksMobile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    position: "relative", // For dropdown positioning
  },
  navLinkContainer: {
    padding: "8px 12px",
    borderRadius: "8px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.2s, color 0.2s",
  },
  navLink: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#38A169",
    textDecoration: "none",
    whiteSpace: "nowrap",
  },
  profileCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
    border: "2px solid #E2E8F0",
    cursor: "pointer",
    background: "none",
    padding: 0,
  },
  profileImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  dropdown: {
    position: "absolute",
    top: "50px", // Below the profile circle
    right: 0,
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1001,
    width: "150px",
    textAlign: "left",
  },
  dropdownMobile: {
    position: "absolute",
    top: "50px",
    right: 0,
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1001,
    width: "120px",
    textAlign: "left",
  },
  dropdownItem: {
    display: "block",
    width: "100%",
    padding: "10px 15px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "16px",
    color: "#38A169",
    textAlign: "left",
    cursor: "pointer",
    transition: "background-color 0.2s",
  },
};

export default NavBar;