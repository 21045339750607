import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import Logo from "../assets/DogTrainerAI.png";

const SubscriptionScreen = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState("7-Day Plan");
  const [donate, setDonate] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
  const [specialOfferExpired, setSpecialOfferExpired] = useState(false);
  const [freeSkillClaimed, setFreeSkillClaimed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false); // Added for animation
  const isMobile = window.innerWidth < 768;

  const planOptions = {
    "7-Day Plan": { id: "prod_RvMQbcGN4CIBmV", price: "$6.99", original_price: "$13.98", days: 7 },
    "1-Month Plan": { id: "prod_RvMVAdq13szD2t", price: "$19.99", original_price: "$39.98", days: 30 },
    "3-Month Plan": { id: "prod_RvMXBVwfb5UKC1", price: "$29.99", original_price: "$59.98", days: 90 }
  };

  const currentPlan = planOptions[selectedPlan];
  const basePrice = parseFloat(
    specialOfferExpired ? currentPlan.original_price.replace("$", "") : currentPlan.price.replace("$", "")
  );
  const totalPrice = donate ? basePrice + 1 : basePrice;
  const formattedTotalPrice = `$${totalPrice.toFixed(2)}`;
  const perDayPrice = (basePrice / currentPlan.days).toFixed(2);
  const formattedPerDayPrice = `$${perDayPrice}`;

  const getDisclaimerText = () => {
    switch (selectedPlan) {
      case "7-Day Plan":
        return `This is a subscription plan starting at $6.99 for the first 7 days. After the promotional period ends, the price will increase to $13.98 every 7 days.`;
      case "1-Month Plan":
        return `This is a subscription plan starting at $19.99 for the first 30 days. After the promotional period ends, the price will increase to $39.98 every 30 days.`;
      case "3-Month Plan":
        return `This is a subscription plan starting at $29.99 for the first 90 days. After the promotional period ends, the price will increase to $59.98 every 90 days.`;
      default:
        return "";
    }
  };

  const getRecurringDisclaimer = () => {
    switch (selectedPlan) {
      case "7-Day Plan":
        return "This is a recurring weekly subscription. You will be charged $13.98 every 7 days after the initial period.";
      case "1-Month Plan":
        return "This is a recurring monthly subscription. You will be charged $39.98 every 30 days after the initial period.";
      case "3-Month Plan":
        return "This is a recurring quarterly subscription. You will be charged $59.98 every 90 days after the initial period.";
      default:
        return "";
    }
  };

  useEffect(() => {
    // Trigger logo animation on mount
    setIsLogoAnimated(true);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFreeSkillClaimed(userData.freeSkillClaimed || false);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (timeLeft > 0 && !specialOfferExpired) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft <= 0) {
      setSpecialOfferExpired(true);
    }
  }, [timeLeft, specialOfferExpired]);

  const handleStartPlan = async () => {
    setLoading(true);
    setError(null);
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated");
        navigate("/login");
        setLoading(false);
        return;
      }

      const timerStart = new Date().toISOString();
      const timerDuration = 600;

      const response = await axios.post(
        "https://createcheckoutsession-wdspuzyvsq-uc.a.run.app/createCheckoutSession",
        {
          userId: user.uid,
          plan: selectedPlan,
          timerStart: timerStart,
          timerDuration: timerDuration,
          donate: donate
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data && response.data.checkoutUrl) {
        window.location.href = response.data.checkoutUrl;
      } else {
        setError("Failed to initiate payment. Please try again.");
        console.error("Stripe checkout session failed:", response.data);
      }
    } catch (error) {
      console.error("Error creating Stripe checkout session:", error);
      setError("An error occurred while connecting to Stripe. Please try again.");
      if (error.response && error.response.status === 404) {
        console.error("Cloud Function not found. Check deployment and URL.");
        setError("Payment service unavailable. Contact support.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFreeSkill = () => {
    navigate("/skills");
  };

  const toggleDonate = () => {
    setDonate((prev) => !prev);
  };

  const getCheckboxStyle = (baseStyle, checkedStyle, isChecked) => ({
    ...baseStyle,
    ...(isChecked ? checkedStyle : {})
  });

  const mins = Math.floor(timeLeft / 60);
  const secs = timeLeft % 60;

  const getStyles = () => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "#fff",
      padding: isMobile ? "10px" : "20px",
      width: "100%",
      boxSizing: "border-box"
    },
    contentWrapper: {
      width: "100%",
      maxWidth: isMobile ? "100%" : "900px",
      textAlign: "center",
      padding: isMobile ? "10px" : "0"
    },
    logo: {
      width: isMobile ? "80px" : "100px",
      height: isMobile ? "80px" : "100px",
      marginBottom: "20px",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      animation: isLogoAnimated ? "fadeBounceIn 1s ease-out" : "none" // Added animation
    },
    mainHeading: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: isMobile ? "36px" : "40px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "20px"
    },
    infoCard: {
      backgroundColor: "#F7FAFC",
      padding: isMobile ? "15px" : "20px",
      borderRadius: "12px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      marginBottom: "20px"
    },
    cardTitle: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: isMobile ? "22px" : "32px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "10px"
    },
    cardText: {
      fontSize: isMobile ? "14px" : "16px",
      color: "#444"
    },
    divider: {
      height: "2px",
      backgroundColor: "#E2E8F0",
      margin: "20px 0",
      width: "100%"
    },
    benefitsCard: {
      backgroundColor: "#F7FAFC",
      padding: isMobile ? "15px" : "20px",
      borderRadius: "12px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      marginBottom: "20px",
      textAlign: "left"
    },
    costCard: {
      backgroundColor: "#F7FAFC",
      padding: isMobile ? "15px" : "20px",
      borderRadius: "12px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      marginBottom: "20px",
      textAlign: "left"
    },
    cardTextLeft: {
      fontSize: isMobile ? "14px" : "16px",
      color: "#444",
      marginBottom: "10px"
    },
    planHeader: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: isMobile ? "26px" : "32px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "20px"
    },
    planContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: "space-between",
      marginBottom: "20px",
      gap: isMobile ? "15px" : "0"
    },
    planLeft: {
      width: isMobile ? "100%" : "45%"
    },
    planRight: {
      width: isMobile ? "100%" : "45%"
    },
    picker: {
      width: "100%",
      padding: isMobile ? "8px" : "10px",
      marginBottom: "15px",
      marginTop: !isMobile ? "20px" : "0",
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      fontSize: "16px",
      backgroundColor: "#fff",
      boxSizing: "border-box",
      outline: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      "&:focus": {
        border: "1px solid #E2E8F0",
        outline: "none"
      }
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      cursor: "pointer",
      backgroundColor: "transparent",
      border: "none",
      padding: "5px"
    },
    checkboxSquare: {
      width: "20px",
      height: "20px",
      border: "2px solid #007BFF",
      backgroundColor: "transparent",
      marginRight: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "background-color 0.2s, border 0.2s"
    },
    checkboxChecked: {
      backgroundColor: "#007BFF",
      borderColor: "#007BFF"
    },
    checkboxText: {
      fontSize: "16px",
      color: "#444"
    },
    planDetailsCard: {
      padding: isMobile ? "15px" : "20px",
      borderRadius: "12px",
      backgroundColor: "#fff",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)"
    },
    planTitle: {
      fontSize: isMobile ? "22px" : "24px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "10px"
    },
    planPrice: {
      fontSize: isMobile ? "16px" : "18px",
      color: "#007BFF",
      marginBottom: "10px"
    },
    planOriginal: {
      fontSize: "16px",
      textDecoration: "line-through",
      color: "#999"
    },
    planPerDay: {
      fontSize: "16px",
      color: "#444"
    },
    totalPriceCard: {
      backgroundColor: "#F7FAFC",
      padding: isMobile ? "15px" : "20px",
      borderRadius: "12px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      marginBottom: "20px"
    },
    totalPriceText: {
      fontSize: isMobile ? "18px" : "20px",
      fontWeight: "bold",
      color: "#2D3748"
    },
    donationNote: {
      fontSize: "14px",
      color: "#666",
      marginTop: "10px"
    },
    disclaimerText: {
      fontSize: "14px",
      paddingTop: "10px",
      color: "#666"
    },
    recurringDisclaimer: {
      fontSize: "14px",
      paddingTop: "10px",
      color: "#666"
    },
    startButton: {
      backgroundColor: "#007BFF",
      padding: isMobile ? "12px" : "15px",
      borderRadius: "8px",
      color: "#fff",
      fontSize: "18px",
      fontWeight: "bold",
      cursor: "pointer",
      border: "none",
      width: isMobile ? "100%" : "auto",
      transition: "background-color 0.2s",
      opacity: loading ? 0.7 : 1,
      pointerEvents: loading ? "none" : "auto"
    },
    startButtonHover: {
      backgroundColor: "#0056b3"
    },
    countdownBar: {
      backgroundColor: "red",
      padding: "15px",
      borderRadius: "8px",
      color: "#fff",
      marginBottom: "20px"
    },
    countdownText: {
      fontSize: "18px",
      fontWeight: "bold"
    },
    freeSkillLink: {
      fontSize: "16px",
      color: "#007BFF",
      textDecoration: "underline",
      cursor: "pointer",
      background: "none",
      border: "none",
      padding: "0px",
      paddingBottom: "15px"
    },
    errorText: {
      fontSize: "16px",
      color: "red",
      marginBottom: "20px",
      textAlign: "center"
    }
  });

  const styles = getStyles();

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <img
          src={Logo}
          style={styles.logo}
          alt="Doglingo AI Logo"
        />
        <h1 style={styles.mainHeading}>Learn the Doglingo!</h1>
        <div style={styles.infoCard}>
          <h2 style={styles.cardTitle}>Did you know?</h2>
          <p style={styles.cardText}>
            Dogs can understand and grasp hundreds of phrases and signals, similar to a two-year-old child.
          </p>
        </div>
        <div style={styles.costCard}>
          <h2 style={styles.cardTitle}>Dog Training Costs in the USA</h2>
          <p style={styles.cardTextLeft}>💰 Average cost: $300-$2,000 per year</p>
          <p style={styles.cardTextLeft}>🏠 Group classes: $20-$75 per session</p>
          <p style={styles.cardTextLeft}>🐕 Private lessons: $50-$120 per hour</p>
          <p style={styles.cardTextLeft}>🏡 Boot camps: $500-$1,250 per week</p>
          <p style={styles.cardTextLeft}>✅ Our plans save you money & time!</p>
        </div>
        <div style={styles.divider} />
        <div style={styles.benefitsCard}>
          <h2 style={styles.cardTitle}>Benefits of Doglingo</h2>
          <p style={styles.cardTextLeft}>🐶 Tailored AI-Powered Training for Your Dog</p>
          <p style={styles.cardTextLeft}>⏱️ Master Commands in Just 10 Minutes Daily</p>
          <p style={styles.cardTextLeft}>💻 Unlock Over 50 Essential Skills & Commands</p>
          <p style={styles.cardTextLeft}>🏡 Train Anytime, Anywhere - No Gear Required</p>
          <p style={styles.cardTextLeft}>✅ Risk-Free with Our 100% Money-Back Guarantee ✅</p>
        </div>
        <h2 style={styles.planHeader}>Choose Your Plan</h2>
        <div style={styles.planContainer}>
          <div style={styles.planLeft}>
            <select
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              style={styles.picker}
            >
              {Object.keys(planOptions).map((plan) => (
                <option key={plan} value={plan}>
                  {plan}
                </option>
              ))}
            </select>
            <button
              style={styles.checkboxContainer}
              onClick={toggleDonate}
              onKeyPress={(e) => e.key === 'Enter' && toggleDonate()}
              tabIndex={0}
            >
              <div style={getCheckboxStyle(styles.checkboxSquare, styles.checkboxChecked, donate)} />
              <span style={styles.checkboxText}>❤️ Donate $1 to Dog Charities</span>
            </button>
          </div>
          <div style={styles.planRight}>
            <div style={styles.planDetailsCard}>
              <h3 style={styles.planTitle}>{selectedPlan}</h3>
              <p style={styles.planPrice}>
                {specialOfferExpired ? currentPlan.original_price : `🔥 Special Offer: ${currentPlan.price} 🔥`}
              </p>
              {!specialOfferExpired && (
                <p style={styles.planOriginal}>Usually {currentPlan.original_price}</p>
              )}
              <p style={styles.planPerDay}>Per Day: {formattedPerDayPrice}</p>
            </div>
          </div>
        </div>
        <div style={styles.totalPriceCard}>
          <p style={styles.totalPriceText}>Total: {formattedTotalPrice}</p>
          {donate && <p style={styles.donationNote}>Includes $1 donation</p>}
          {!specialOfferExpired && <p style={styles.disclaimerText}>{getDisclaimerText()}</p>}
          {specialOfferExpired && <p style={styles.recurringDisclaimer}>{getRecurringDisclaimer()}</p>}
        </div>
        {error && <p style={styles.errorText}>{error}</p>}
        <button
          style={{ ...styles.startButton, ...(isHovered && !loading && styles.startButtonHover) }}
          onClick={handleStartPlan}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={loading}
        >
          {loading ? "Processing..." : "🚀 Start My Plan"}
        </button>
        <div style={styles.divider} />
        {!specialOfferExpired && timeLeft > 0 && (
          <div style={styles.countdownBar}>
            <p style={styles.countdownText}>
              🔥 Special Offer Expires in {mins}:{secs < 10 ? `0${secs}` : secs} 🔥
            </p>
          </div>
        )}
        {!freeSkillClaimed && (
          <button style={styles.freeSkillLink} onClick={handleFreeSkill}>
            I&apos;d like to try it out with one free skill first
          </button>
        )}
        
      </div>

      {/* Inline CSS for keyframes */}
      <style>
        {`
          @keyframes fadeBounceIn {
            0% {
              opacity: 0;
              transform: scale(0.8) translateY(-20px);
            }
            60% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1) translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

export default SubscriptionScreen;