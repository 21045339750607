import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { doc, getDoc, collection, getDocs, query, orderBy, setDoc } from "firebase/firestore";
import { firestore, auth } from "../firebase.js";
import { chat } from "../api/grok.js";
import { onAuthStateChanged } from "firebase/auth";
import { ArrowUpward } from "@mui/icons-material"; // Only keeping ArrowUpward for send button
import Logo from "../assets/DogTrainerAI.png";

const TrainerScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category");
  const skillId = searchParams.get("skill_id");
  const skillName = searchParams.get("skill_name");

  const [skill, setSkill] = useState(null);
  const [step, setStep] = useState(0);
  const [aiChatActive, setAiChatActive] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingDots, setLoadingDots] = useState("");
  const [userData, setUserData] = useState(null);
  const [dogData, setDogData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);
  const [hoverStates, setHoverStates] = useState({
    clicker: false,
    help: false,
    previous: false,
    next: false,
    complete: false,
    back: false,
  });
  const [showIntroDialog, setShowIntroDialog] = useState(false);
  const isMobile = window.innerWidth < 768;

  const getStyles = () => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#fff",
      padding: isMobile ? "10px" : "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "600px",
      padding: isMobile ? "10px" : "20px",
      boxSizing: "border-box",
      position: "relative",
    },
    dialogOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    dialogBox: {
      backgroundColor: "#fff",
      padding: isMobile ? "15px" : "20px",
      borderRadius: "12px",
      maxWidth: isMobile ? "90%" : "400px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      textAlign: "center",
    },
    dialogHeading: {
      fontSize: isMobile ? "20px" : "24px",
      fontWeight: "bold",
      color: "#444",
      marginBottom: "15px",
    },
    dialogText: {
      fontSize: isMobile ? "14px" : "16px",
      color: "#444",
      lineHeight: "1.4",
    },
    bulletContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      margin: "10px 0",
      textAlign: "left",
    },
    bulletIcon: {
      marginRight: "8px",
      color: "#38A169", // Still applies to emojis for consistency
    },
    dialogButton: {
      padding: "8px 20px",
      borderRadius: "50px",
      backgroundColor: "#38A169",
      border: "none",
      color: "#fff",
      fontSize: "16px",
      cursor: "pointer",
      transition: "background-color 0.2s",
      marginTop: "15px",
    },
    dialogButtonHover: {
      backgroundColor: "#2F855A",
    },
    closeButton: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: "#fff",
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      fontSize: "18px",
      color: "#444",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      marginRight: "10px",
    },
    progressContainer: {
      width: "100%",
      marginBottom: "20px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    progressBar: {
      flex: 1,
      height: "10px",
      backgroundColor: "#E2E8F0",
      borderRadius: "5px",
      overflow: "hidden",
    },
    progressFill: {
      height: "100%",
      backgroundColor: "#38A169",
      borderRadius: "5px",
      transition: "width 0.3s ease",
    },
    heading: {
      fontSize: isMobile ? "24px" : "32px",
      fontWeight: "bold",
      color: "#444",
      textAlign: "center",
      marginBottom: "15px",
    },
    text: {
      fontSize: isMobile ? "16px" : "18px",
      color: "#444",
      textAlign: "center",
      marginBottom: "20px",
    },
    image: {
      width: "100%",
      maxWidth: isMobile ? "300px" : "400px",
      height: "auto",
      borderRadius: "20px",
      margin: "0 auto 20px auto",
      display: "block",
    },
    actionButtonContainer: {
      display: "flex",
      justifyContent: "center",
      gap: isMobile ? "10px" : "20px",
      marginBottom: "20px",
    },
    navContainer: {
      display: "flex",
      justifyContent: "center",
      gap: isMobile ? "10px" : "20px",
    },
    button: {
      padding: isMobile ? "8px 16px" : "10px 20px",
      borderRadius: "50px",
      backgroundColor: "#fff",
      border: "1px solid #E2E8F0",
      color: "#444",
      fontSize: "16px",
      fontWeight: "400",
      cursor: "pointer",
      width: "150px",
      transition: "box-shadow 0.2s",
    },
    buttonHover: {
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    chatContainer: {
      width: "100%",
      maxWidth: "600px",
      padding: isMobile ? "10px" : "20px",
      boxSizing: "border-box",
      backgroundColor: "#F7FAFC",
      borderRadius: "12px",
      minHeight: isMobile ? "400px" : "600px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    chatLogo: {
      width: "100px",
      height: "100px",
      objectFit: "contain",
      marginBottom: "10px",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      animation: isLogoAnimated ? "fadeBounceIn 1s ease-out" : "none",
    },
    chatBox: {
      flex: 1,
      width: "100%",
      overflowY: "auto",
      marginTop: "10px",
      padding: "10px",
      boxSizing: "border-box",
    },
    userMessage: {
      textAlign: "right",
      backgroundColor: "#007BFF",
      color: "#fff",
      padding: "10px",
      borderRadius: "10px",
      margin: "5px 0",
      maxWidth: "80%",
      alignSelf: "flex-end",
    },
    aiMessage: {
      textAlign: "left",
      backgroundColor: "#E6E6E6",
      color: "#000",
      padding: "10px",
      borderRadius: "10px",
      margin: "5px 0",
      maxWidth: "80%",
      alignSelf: "flex-start",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginTop: "20px",
    },
    textInput: {
      flex: 1,
      padding: "10px",
      marginRight: "10px",
      borderRadius: "5px",
      border: "1px solid #E2E8F0",
      fontSize: "18px",
      boxSizing: "border-box",
      outline: "none",
    },
    sendButton: {
      padding: "10px",
      borderRadius: "50%",
      backgroundColor: "#007BFF",
      border: "none",
      color: "#fff",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "40px",
      height: "40px",
    },
    backButton: {
      padding: "10px 20px",
      borderRadius: "50px",
      backgroundColor: "#fff",
      border: "1px solid #E2E8F0",
      color: "#444",
      fontSize: "16px",
      fontWeight: "400",
      cursor: "pointer",
      marginTop: "20px",
      width: "150px",
      transition: "box-shadow 0.2s",
    },
    loadingText: {
      FONTSIZE: "18PX",
      COLOR: "#666",
      TEXTALIGN: "CENTER",
      MARGINTOP: "20PX",
    },
    errorText: {
      fontSize: "16px",
      color: "red",
      textAlign: "center",
      marginTop: "10px",
    },
  });

  const styles = getStyles();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const introKey = `hasSeenTrainerIntro_${user.uid}`;
        const hasSeenIntro = localStorage.getItem(introKey);
        if (!hasSeenIntro) {
          setShowIntroDialog(true);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleDismissDialog = () => {
    const user = auth.currentUser;
    if (user) {
      const introKey = `hasSeenTrainerIntro_${user.uid}`;
      localStorage.setItem(introKey, "true");
    }
    setShowIntroDialog(false);
  };

  useEffect(() => {
    const fetchSkillAndUserData = async (user) => {
      if (!categoryId || !skillId) {
        setError("Missing category or skill ID");
        return;
      }

      setLoading(true);
      try {
        const skillDocRef = doc(firestore, `skills/${categoryId}/skills`, skillId);
        const skillDoc = await getDoc(skillDocRef);
        if (skillDoc.exists()) {
          const skillData = skillDoc.data();
          let steps = [];
          if (skillData.steps && Array.isArray(skillData.steps)) {
            steps = skillData.steps;
          } else {
            const stepsQuery = query(
              collection(firestore, `skills/${categoryId}/skills/${skillId}/steps`),
              orderBy("order")
            );
            const stepsSnapshot = await getDocs(stepsQuery);
            steps = stepsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
          }
          setSkill({
            name: skillData.name || skillName,
            steps: steps.map((step) => ({
              instruction: step.instruction || "No instruction provided",
              image_url: step.image_url || "https://placehold.co/600x300?text=No+Image",
            })),
          });
        } else {
          setError("Skill not found in Firestore");
        }

        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          await setDoc(userDocRef, { email: user.email }, { merge: true });
          setUserData({ email: user.email });
        }

        const dogsRef = collection(firestore, "users", user.uid, "dogs");
        const dogsSnapshot = await getDocs(dogsRef);
        if (!dogsSnapshot.empty) {
          const firstDog = dogsSnapshot.docs[0].data();
          setDogData(firstDog);
        } else {
          setError("No dog found for this user.");
        }
      } catch (err) {
        setError("Failed to load data: " + err.message);
      } finally {
        setLoading(false);
        setDataLoaded(true);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSkillAndUserData(user);
      } else {
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [categoryId, skillId, skillName, navigate]);

  useEffect(() => {
    if (aiChatActive) {
      setIsLogoAnimated(true);
    }
  }, [aiChatActive]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingDots((prev) => (prev.length < 3 ? prev + "." : ""));
      }, 300);
      return () => clearInterval(interval);
    } else {
      setLoadingDots("");
    }
  }, [loading]);

  const progress = skill ? (step >= skill.steps.length ? 100 : (step / skill.steps.length) * 100) : 0;

  const playSound = () => {
    const audio = new Audio("/assets/yes.mp3");
    audio
      .play()
      .then(() => console.log("Clicker sound played!"))
      .catch((err) => console.error("Audio error:", err.message));
  };

  const getSystemMessage = () => {
    return `
      You are a friendly professional AI dog trainer, specialized in teaching people how to speak the doglingo and interact with dogs to get them to learn commands.
      You don't recommend them to go to a dog trainer; you are the dog trainer.
      Give short, direct answers (3 - 5 sentences max).
      Personalize your responses using the following user and dog info:
      - User: ${userData?.firstName || "Unknown"}, Email: ${userData?.email || "N/A"}
      - Dog: Name: ${dogData?.dogName || "Unknown"}, Breed: ${dogData?.dogBreed || "Unknown"}, Age: ${dogData?.dogAge || "Unknown"}, Gender: ${dogData?.dogGender || "Unknown"}
      - Current Skill: ${skill?.name || "Unknown"}
      - Current Step: Step ${step + 1} - "${skill?.steps[step]?.instruction || "No instruction available"}"
    `;
  };

  const sendMessage = async () => {
    if (!userInput.trim() || loading || !dataLoaded) return;
    const newChat = [...chatHistory, { role: "user", content: userInput }];
    setChatHistory(newChat);
    setLoading(true);
    setError(null);
    try {
      const systemMessage = getSystemMessage();
      const response = await chat(
        [{ role: "system", content: systemMessage }, ...newChat],
        { temperature: 0.3, max_tokens: 100, model: "grok-2-1212" }
      );
      const assistantMessage = response?.data?.choices?.[0]?.message?.content || "Try a firm 'sit' and a treat reward. Be patient!";
      setChatHistory([...newChat, { role: "assistant", content: assistantMessage }]);
    } catch (err) {
      setError(`Failed to get a response: ${err.message}`);
      setChatHistory([...newChat, { role: "assistant", content: "Try a firm 'sit' and a treat reward. Be patient!" }]);
    }
    setLoading(false);
    setUserInput("");
  };

  const handleSendPress = () => {
    sendMessage();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  if (loading && !aiChatActive) {
    return <p style={styles.loadingText}>Fetching skill..{loadingDots}</p>;
  }

  if (error && !aiChatActive) {
    return <p style={styles.errorText}>{error}</p>;
  }

  const handleHover = (buttonName, isHovering) => {
    setHoverStates((prev) => ({ ...prev, [buttonName]: isHovering }));
  };

  return (
    <div style={styles.container}>
      {/* One-time Intro Dialog */}
      {showIntroDialog && (
        <div style={styles.dialogOverlay}>
          <div style={styles.dialogBox}>
            <h2 style={styles.dialogHeading}>How to Train</h2>
            <p style={{ ...styles.dialogText, textAlign: "center" }}>
              Get ready to learn the Doglingo! 🎉
            </p>
            <div style={styles.bulletContainer}>
              <span style={{ ...styles.bulletIcon }}>➡️</span>
              <span style={styles.dialogText}>
                Use the <em>Next</em> and <em>Previous</em> buttons to move through the steps and check out the illustrations.
              </span>
            </div>
            <div style={styles.bulletContainer}>
              <span style={{ ...styles.bulletIcon }}>🎵</span>
              <span style={styles.dialogText}>
                When your pup nails it (or gets close), give them a treat and hit the <em>Clicker</em> button for a fun reinforcement.
              </span>
            </div>
            <div style={styles.bulletContainer}>
              <span style={{ ...styles.bulletIcon }}>❓</span>
              <span style={styles.dialogText}>
                Need a paw? Tap the <em>Help</em> button—Doglingo AI is here for you!
              </span>
            </div>
            <div style={styles.bulletContainer}>
              <span style={{ ...styles.bulletIcon }}>⭐</span>
              <span style={styles.dialogText}>
                Once you’re done, rate how well your dog learned the skill to track their progress.
              </span>
            </div>
            <p style={{ ...styles.dialogText, textAlign: "center", paddingTop: "15px" }}>
              Let’s make training a tail-wagging success!
            </p>
            <button
              style={styles.dialogButton}
              onClick={handleDismissDialog}
              onMouseEnter={(e) => (e.target.style.backgroundColor = styles.dialogButtonHover.backgroundColor)}
              onMouseLeave={(e) => (e.target.style.backgroundColor = styles.dialogButton.backgroundColor)}
            >
              Start Training
            </button>
          </div>
        </div>
      )}

      <div style={styles.contentWrapper}>
        {aiChatActive ? (
          <>
            <img src={Logo} style={styles.chatLogo} alt="Doglingo AI Logo" />
            <h2 style={styles.heading}>Doglingo AI</h2>
            <p style={styles.text}>{`Hey! What's going on with ${dogData?.dogName || "pup"}?`}</p>
            <div style={styles.chatBox}>
              {chatHistory.map((chat, index) => (
                <div key={index} style={chat.role === "user" ? styles.userMessage : styles.aiMessage}>
                  <p>{chat.content || "Empty message"}</p>
                </div>
              ))}
            </div>
            {error && <p style={styles.errorText}>{error}</p>}
            <div style={styles.inputContainer}>
              <textarea
                style={styles.textInput}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Type your message..."
                onKeyPress={handleKeyPress}
              />
              <button style={styles.sendButton} onClick={handleSendPress} disabled={loading || !dataLoaded}>
                {loading ? loadingDots : <ArrowUpward />}
              </button>
            </div>
            <button
              style={{ ...styles.backButton, ...(hoverStates.back && styles.buttonHover) }}
              onClick={() => setAiChatActive(false)}
              onMouseEnter={() => handleHover("back", true)}
              onMouseLeave={() => handleHover("back", false)}
            >
              Back
            </button>
          </>
        ) : (
          <>
            <div style={styles.progressContainer}>
              <button style={styles.closeButton} onClick={() => navigate("/skills")}>
                X
              </button>
              <div style={styles.progressBar}>
                <div style={{ ...styles.progressFill, width: `${progress}%` }} />
              </div>
            </div>
            <h2 style={styles.heading}>🐶 {skill?.name} - Step {step + 1}</h2>
            <p style={styles.text}>{skill?.steps[step]?.instruction || "No instruction available"}</p>
            <img
              src={skill?.steps[step]?.image_url || "https://placehold.co/600x300?text=No+Image"}
              style={styles.image}
              alt="Step"
            />
            <div style={styles.actionButtonContainer}>
              <button
                style={{ ...styles.button, ...(hoverStates.clicker && styles.buttonHover) }}
                onClick={playSound}
                onMouseEnter={() => handleHover("clicker", true)}
                onMouseLeave={() => handleHover("clicker", false)}
              >
                🎵 Clicker
              </button>
              <button
                style={{ ...styles.button, ...(hoverStates.help && styles.buttonHover) }}
                onClick={() => setAiChatActive(true)}
                onMouseEnter={() => handleHover("help", true)}
                onMouseLeave={() => handleHover("help", false)}
              >
                ❓ Ask AI
              </button>
            </div>
            <div style={styles.navContainer}>
              {step > 0 && (
                <button
                  style={{ ...styles.button, ...(hoverStates.previous && styles.buttonHover) }}
                  onClick={() => setStep(step - 1)}
                  onMouseEnter={() => handleHover("previous", true)}
                  onMouseLeave={() => handleHover("previous", false)}
                >
                  ⬅️ Previous
                </button>
              )}
              {step < skill?.steps.length - 1 ? (
                <button
                  style={{ ...styles.button, ...(hoverStates.next && styles.buttonHover) }}
                  onClick={() => setStep(step + 1)}
                  onMouseEnter={() => handleHover("next", true)}
                  onMouseLeave={() => handleHover("next", false)}
                >
                  Next ➡️
                </button>
              ) : (
                <button
                  style={{ ...styles.button, ...(hoverStates.complete && styles.buttonHover) }}
                  onClick={() =>
                    navigate(
                      `/rate?category=${categoryId}&skill_id=${skillId}&skill_name=${encodeURIComponent(skillName)}`
                    )
                  }
                  onMouseEnter={() => handleHover("complete", true)}
                  onMouseLeave={() => handleHover("complete", false)}
                >
                  ✅ Complete
                </button>
              )}
            </div>
          </>
        )}
      </div>

      <style>
        {`
          @keyframes fadeBounceIn {
            0% {
              opacity: 0;
              transform: scale(0.8) translateY(-20px);
            }
            60% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1) translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

export default TrainerScreen;