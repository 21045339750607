import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar.js";
import MainScreen from "./screens/MainScreen.js";
import OnboardingScreen from "./screens/OnboardingScreen.js";
import LoginScreen from "./screens/LoginScreen.js";
import SkillsScreen from "./screens/SkillsScreen.js";
import TrainerScreen from "./screens/TrainerScreen.js";
import RateScreen from "./screens/RateScreen.js";
import SubscriptionScreen from "./screens/SubscriptionScreen.js";
import SubscriptionSuccess from "./screens/SubscriptionSuccess.js";
import SubscriptionCancel from "./screens/SubscriptionCancel.js";
import EditProfileScreen from "./screens/EditProfileScreen.js";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen.js";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen.js";
import TermsOfServiceScreen from "./screens/TermsOfServiceScreen.js";
import DogDashboard from "./screens/DogDashboardScreen.js";
import TestUpload from "./screens/TestUpload.js";



const App = () => {
  const location = useLocation(); // Get current route

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top whenever location.pathname changes
  }, [location.pathname]); // Trigger on pathname change

  return (
    <div style={styles.appContainer}>
      {/* Conditionally hide navbar on certain pages */}
      {!["/onboarding", "/trainer", "/login", "/subscription", "/rate", "/forgot-password"].includes(location.pathname) && <NavBar />}

      {/* Page content with full viewport height to avoid stacking */}
      <div style={styles.pageContainer}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<MainScreen />} />
          <Route path="/onboarding" element={<OnboardingScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/skills" element={<SkillsScreen />} />
          <Route path="/trainer" element={<TrainerScreen />} />
          <Route path="/rate" element={<RateScreen />} />
          <Route path="/subscription" element={<SubscriptionScreen />} />
          <Route path="/subscription-success" element={<SubscriptionSuccess />} />
          <Route path="/subscription-cancel" element={<SubscriptionCancel />} />
          <Route path="/edit-profile" element={<EditProfileScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
          <Route path="/terms-of-service" element={<TermsOfServiceScreen />} />
          <Route path="/dashboard" element={<DogDashboard />} />
          <Route path="/test-upload" element={<TestUpload />} />
        </Routes>
      </div>
    </div>
  );
};

// Fix overlapping and make sure new screens replace the previous one
const styles = {
  appContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
  },
  pageContainer: {
    flex: 1, // Makes sure the Routes take up full space and prevent stacking
    width: "100%",
    overflow: "hidden", // Prevents any residual overlapping
  },
};

export default App;